import awsmobile from 'aws-exports';

import { API, graphqlOperation, Auth } from 'aws-amplify';

const apiName = "app";

// types of action
const Types = {
  STRIPE_LIST: "STRIPE_LIST",
  STRIPE_INTENT: "STRIPE_INTENT",
  STRIPE_PAYMENT_METHODS: "STRIPE_PAYMENT_METHODS",
  STRIPE_CREATE_CUSTOMER: "STRIPE_CREATE_CUSTOMER",
  STRIPE_CREATE_SUBSCRIPTION: "STRIPE_CREATE_SUBSCRIPTION",
  STRIPE_CREATING_SUBSCRIPTION: "STRIPE_CREATING_SUBSCRIPTION",
};


const stripeSubscriptions = response => ({
  type: Types.STRIPE_LIST,
  payload: response.data
});

const getStripeSubscriptions = () => {

  return (dispatch,getState) => {

    const state = getState()

    const stripeCustomerID = state.business.item.stripeID

    if (stripeCustomerID){
      // let path = "/items/delegation";
      let path = "/subscription/stripe/list";
      let myInit = {
        response: true, // OPTIONAL (return entire response object instead of response.data)
        queryStringParameters: {
          customerID: stripeCustomerID
        },
      };

      API.get(apiName, path, myInit)
            .then(response => {
          dispatch(stripeSubscriptions(response))
        })
    }
  }
};

const stripeIntent = response => ({
  type: Types.STRIPE_INTENT,
  payload: response.data
});

const getStripeIntent = () => {

  return (dispatch,getState) => {

    const state = getState()

    const stripeCustomerID = state.business.item.stripeID

    if (stripeCustomerID){
      // let path = "/items/delegation";
      let path = "/subscription/stripe/intent";
      let myInit = {
        response: true, // OPTIONAL (return entire response object instead of response.data)
        queryStringParameters: {
          customerID: stripeCustomerID
        },
      };

      API.get(apiName, path, myInit)
            .then(response => {
          dispatch(stripeIntent(response))
        })
    }
  }
};

const stripePaymentMethods = response => ({
  type: Types.STRIPE_PAYMENT_METHODS,
  payload: response.data
});

const getStripePaymentMethods = () => {

  return (dispatch,getState) => {

    const state = getState()

    const stripeCustomerID = state.business.item.stripeID

    if (stripeCustomerID){
      // let path = "/items/delegation";
      let path = "/subscription/stripe/paymentMethods";
      let myInit = {
        response: true, // OPTIONAL (return entire response object instead of response.data)
        queryStringParameters: {
          customerID: stripeCustomerID
        },
      };

      API.get(apiName, path, myInit)
            .then(response => {
          dispatch(stripePaymentMethods(response))
        })
    }
  }
};

const stripeCreateCustomer = response => ({
  type: Types.STRIPE_CREATE_CUSTOMER,
  payload: response.data
});

const createStripeCustomer = () => {
      console.log('inside createStripeCustomer')

  return (dispatch,getState) => {

    const state = getState()

    const businessID = state.businessID
    const name = state.business.item.name
    const email = state.business.item.billingEmail
    const stripeCustomerID = state.business.item.stripeID

    if (!stripeCustomerID){
      // let path = "/items/delegation";
      let path = "/subscription/stripe/createCustomer";
      let myInit = {
        response: true, // OPTIONAL (return entire response object instead of response.data)
        queryStringParameters: {
          customerID: stripeCustomerID
        },
        body: {
        	businessID,
			name,
			email
        }
      };

      API.post(apiName, path, myInit)
            .then(response => {
          dispatch(stripeCreateCustomer(response))
        })
    }
  }
};


const stripeCreateSubscription = response => ({
  type: Types.STRIPE_CREATE_SUBSCRIPTION,
  payload: response.data
});

const stripeCreatingSubscription = response => ({
  type: Types.STRIPE_CREATING_SUBSCRIPTION,
});

const createStripeSubscription = () => {
    console.log('inside createStripeSubscription')

  return (dispatch,getState) => {

    dispatch(stripeCreatingSubscription())

    const state = getState()

    const businessID = state.businessID
    const stripeCustomerID = state.business.item.stripeID

    if (state.subscription.stripe && state.subscription.stripe.list.length == 0){
      // let path = "/items/delegation";
      let path = "/subscription/stripe/createSubscription";
      let myInit = {
        response: true, // OPTIONAL (return entire response object instead of response.data)
        body: {
        	businessID,
          	customerID: stripeCustomerID
        }
      };

      API.post(apiName, path, myInit)
            .then(response => {
          dispatch(stripeCreateSubscription(response))
        })
    }
  }
};


const Actions = {
  createStripeCustomer,
  createStripeSubscription,
  getStripeSubscriptions,
  getStripeIntent,
  getStripePaymentMethods,
};


export {Types, Actions}