import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/app/action';
import {Actions as INTEGRATIONACTIONS} from 'store/integration/action';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';


const Dash = ({
	getApp,
	listApps,
	updateApp,
	createApp,
	updateConfig,
	updateSetting,
	businessID,
	app,
	apps,
	replaceApp,
	id
}) => {

	const [newApp, setNewApp] = useState(false)

	useEffect(() => {

		if (id){
			getApp(id)
		}

		listApps()
		// listApps('MindBody')

	}, [])

	useEffect(() => {

		if (id){
			// get list of sites for this dash account
		}

	}, [id])

  	let history = useHistory();
	// let { id } = useParams();

	const onChange = (e) => {
		console.log('onChange',e)
	    updateConfig && updateConfig(e.target.name,e.target.value)
	}


	const onChangeSetting = (e) => {
		console.log('onChange',e)
	    updateSetting && updateSetting(e.target.name,e.target.value)
	}

	const save = () => {

		const config = {
			...app.config
		}

		delete config.name

		const input = {
			id: app.id || uuidv4(),
			name: app.config.name || app.name,
			config: app && JSON.stringify(app.config),
			businessID,
			app: "DASH",
		}

		if (app.id){
			// update app
			updateApp(input)
		} else {
			// create app
			createApp(input)
		}

	}



	const selectDefaultStyle = {
	  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
	};

	const appOptions = apps && apps
		// .filter(({app}) => app == "DASH")
		.map(app => ({
			label: app.name,
			value: app.id
		}))

	console.log('appOptions',appOptions)


	if (apps && !newApp) {
		return (
			<React.Fragment>
		      
		      <div class="col-span-6 sm:col-span-4">
		        <label class="block text-sm font-medium leading-5 text-gray-700">DASH Account</label>
		        {/*<input id="givenName" name="givenName" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />*/}
		        <Select
		          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
		          styles={selectDefaultStyle}
		          value={appOptions && appOptions.find(item => item.value == id) || {value:"",label:""}}
		          onChange={(selected) => {
			          	// remove the current Dash App from Integration
			          	// Add new dash app in integration
			          	replaceApp(id,selected.value)
		            }
		          }
		          options={appOptions}
		        />

		    	{/* Allow for selection of Location (or within the actual dash app */}

		        <a
		            onClick={() => {setNewApp(true)}}
		            style={{marginTop:'12px', fontSize: "10px", marginTop:"10px", cursor: "pointer"}}
		          >
		          	Add another DASH Account
		        </a>
		      </div>
		    </React.Fragment>
		)
	}

	// Edit / Create Mode

	return (

		<React.Fragment>
	      <div className="col-span-6 sm:col-span-4">
	        <label htmlFor="apiKey" className="block text-sm font-medium leading-5 text-gray-700">Name (used to identify multiple connections)</label>
	        <input id="name" name="name" onChange={onChange} value={app && app.config.name || app && app.name || ""} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>
	      <div className="col-span-6 sm:col-span-4 mt-5">
	        <label htmlFor="platformKey" className="block text-sm font-medium leading-5 text-gray-700">Platform Key</label>
	        <input id="platformKey" name="platformKey" onChange={onChange} value={app && app.config.platformKey || ""} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>
	      <div className="col-span-6 sm:col-span-4 mt-5">
	        <label htmlFor="companyID" className="block text-sm font-medium leading-5 text-gray-700">Company ID</label>
	        <input id="companyID" name="companyID" onChange={onChange} value={app && app.config.companyID || ""} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>
	      <div className="shadow-sm rounded-md mt-5 text-right">
	        <button onClick={save} className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out">
	          Save DASH Config
	        </button>
	      </div>

	      { apps && apps.length > 0 && 
	      	<a
	            onClick={() => {setNewApp(false)}}
	            style={{marginTop:'12px', fontSize: "10px", marginTop:"10px", cursor: "pointer"}}
	          >
	          	Use Existing DASH Account
	        </a>
	      }

	    </React.Fragment>

	)
}


const mapStateToProps = (state) => {
	console.log('state.integration',state.integration)
	return {
	  	app: state.app,
	  	apps: state.apps && state.apps.filter(({app}) => app == "DASH"),
	  	businessID: state.businessID,
	  	// integration: state.integration,
	  	// username: state.integration && state.integration.settings && state.integration.settings['starrez.username'],
	  	// password: state.integration && state.integration.settings && state.integration.settings['starrez.password'],
	  	url: state.integration && state.integration.settings && state.integration.settings['starrez.url'],
  	}
}

const mapDispatchToProps = dispatch => ({

  getApp: (id) => dispatch(ACTIONS.getItem(id)),
  updateApp: (input) => dispatch(ACTIONS.updateItem(input)),
  createApp: (input) => dispatch(ACTIONS.createItem(input)),

  updateConfig: (key,value) => dispatch(ACTIONS.updateConfig(key,value)),
  listApps: (app) => dispatch(ACTIONS.listItems(app)),
  updateSetting: (key,value) => dispatch(INTEGRATIONACTIONS.updateSetting(key,value)),
  replaceApp: (oldApp,newApp) => dispatch(INTEGRATIONACTIONS.replaceApp(oldApp,newApp)),
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dash) 