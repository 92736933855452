/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      name
      owner
      phone
      billingAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
      }
      billingEmail
      createdAt
      updatedAt
      stripeID
      goCardlessID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      name
      owner
      phone
      billingAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
      }
      billingEmail
      createdAt
      updatedAt
      stripeID
      goCardlessID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      name
      owner
      phone
      billingAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
      }
      billingEmail
      createdAt
      updatedAt
      stripeID
      goCardlessID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAppCredential = /* GraphQL */ `
  mutation CreateAppCredential(
    $input: CreateAppCredentialInput!
    $condition: ModelAppCredentialConditionInput
  ) {
    createAppCredential(input: $input, condition: $condition) {
      id
      name
      businessID
      userID
      config
      app
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAppCredential = /* GraphQL */ `
  mutation UpdateAppCredential(
    $input: UpdateAppCredentialInput!
    $condition: ModelAppCredentialConditionInput
  ) {
    updateAppCredential(input: $input, condition: $condition) {
      id
      name
      businessID
      userID
      config
      app
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAppCredential = /* GraphQL */ `
  mutation DeleteAppCredential(
    $input: DeleteAppCredentialInput!
    $condition: ModelAppCredentialConditionInput
  ) {
    deleteAppCredential(input: $input, condition: $condition) {
      id
      name
      businessID
      userID
      config
      app
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIntegrationSettings = /* GraphQL */ `
  mutation CreateIntegrationSettings(
    $input: CreateIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    createIntegrationSettings(input: $input, condition: $condition) {
      id
      name
      type
      businessID
      userID
      apps
      settings
      status
      createdAt
      updatedAt
      lastSync
      _version
      _deleted
      _lastChangedAt
      identifiers {
        items {
          id
          integrationID
          businessID
          app
          externalID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateIntegrationSettings = /* GraphQL */ `
  mutation UpdateIntegrationSettings(
    $input: UpdateIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    updateIntegrationSettings(input: $input, condition: $condition) {
      id
      name
      type
      businessID
      userID
      apps
      settings
      status
      createdAt
      updatedAt
      lastSync
      _version
      _deleted
      _lastChangedAt
      identifiers {
        items {
          id
          integrationID
          businessID
          app
          externalID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteIntegrationSettings = /* GraphQL */ `
  mutation DeleteIntegrationSettings(
    $input: DeleteIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    deleteIntegrationSettings(input: $input, condition: $condition) {
      id
      name
      type
      businessID
      userID
      apps
      settings
      status
      createdAt
      updatedAt
      lastSync
      _version
      _deleted
      _lastChangedAt
      identifiers {
        items {
          id
          integrationID
          businessID
          app
          externalID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createIntegrationIdentifier = /* GraphQL */ `
  mutation CreateIntegrationIdentifier(
    $input: CreateIntegrationIdentifierInput!
    $condition: ModelIntegrationIdentifierConditionInput
  ) {
    createIntegrationIdentifier(input: $input, condition: $condition) {
      id
      integrationID
      businessID
      app
      externalID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIntegrationIdentifier = /* GraphQL */ `
  mutation UpdateIntegrationIdentifier(
    $input: UpdateIntegrationIdentifierInput!
    $condition: ModelIntegrationIdentifierConditionInput
  ) {
    updateIntegrationIdentifier(input: $input, condition: $condition) {
      id
      integrationID
      businessID
      app
      externalID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIntegrationIdentifier = /* GraphQL */ `
  mutation DeleteIntegrationIdentifier(
    $input: DeleteIntegrationIdentifierInput!
    $condition: ModelIntegrationIdentifierConditionInput
  ) {
    deleteIntegrationIdentifier(input: $input, condition: $condition) {
      id
      integrationID
      businessID
      app
      externalID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIntegrationAlert = /* GraphQL */ `
  mutation CreateIntegrationAlert(
    $input: CreateIntegrationAlertInput!
    $condition: ModelIntegrationAlertConditionInput
  ) {
    createIntegrationAlert(input: $input, condition: $condition) {
      id
      alertType
      integrationID
      businessID
      subject
      message
      sent
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateIntegrationAlert = /* GraphQL */ `
  mutation UpdateIntegrationAlert(
    $input: UpdateIntegrationAlertInput!
    $condition: ModelIntegrationAlertConditionInput
  ) {
    updateIntegrationAlert(input: $input, condition: $condition) {
      id
      alertType
      integrationID
      businessID
      subject
      message
      sent
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteIntegrationAlert = /* GraphQL */ `
  mutation DeleteIntegrationAlert(
    $input: DeleteIntegrationAlertInput!
    $condition: ModelIntegrationAlertConditionInput
  ) {
    deleteIntegrationAlert(input: $input, condition: $condition) {
      id
      alertType
      integrationID
      businessID
      subject
      message
      sent
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createIntegrationQueue = /* GraphQL */ `
  mutation CreateIntegrationQueue(
    $input: CreateIntegrationQueueInput!
    $condition: ModelIntegrationQueueConditionInput
  ) {
    createIntegrationQueue(input: $input, condition: $condition) {
      id
      integrationID
      businessID
      sourceID
      source
      destinationID
      destination
      type
      errors
      status
      attempts
      timeSynced
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIntegrationQueue = /* GraphQL */ `
  mutation UpdateIntegrationQueue(
    $input: UpdateIntegrationQueueInput!
    $condition: ModelIntegrationQueueConditionInput
  ) {
    updateIntegrationQueue(input: $input, condition: $condition) {
      id
      integrationID
      businessID
      sourceID
      source
      destinationID
      destination
      type
      errors
      status
      attempts
      timeSynced
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIntegrationQueue = /* GraphQL */ `
  mutation DeleteIntegrationQueue(
    $input: DeleteIntegrationQueueInput!
    $condition: ModelIntegrationQueueConditionInput
  ) {
    deleteIntegrationQueue(input: $input, condition: $condition) {
      id
      integrationID
      businessID
      sourceID
      source
      destinationID
      destination
      type
      errors
      status
      attempts
      timeSynced
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
