import {
  getAppCredential,
  appCredentialByBusinessByApp
} from 'graphql/queries'

import {
  createAppCredential,
  updateAppCredential,
} from 'graphql/mutations';
       
import { API, graphqlOperation, Auth } from 'aws-amplify';
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';

// types of action
const Types = {
  APP_GET_ITEM: "APP_GET_ITEM",
  APP_LIST_ITEMS: "APP_LIST_ITEMS",
  APP_UPDATE_ITEM: "APP_UPDATE_ITEM",
  APP_CREATE_ITEM: "APP_CREATE_ITEM",
  APP_UPDATE_CONFIG: "APP_UPDATE_CONFIG",
  APP_LOADING: "APP_LOADING",
};

const dispatchLoading =  loading => ({
  type: Types.APP_LOADING,
  loading
});

const dispatchGetItem = response => ({
  type: Types.APP_GET_ITEM,
  payload: response
});

const dispatchListItems = response => ({
  type: Types.APP_LIST_ITEMS,
  payload: response
});

const updateConfig = (key,value) => ({
  type: Types.APP_UPDATE_CONFIG,
  key,
  value
});

const listItems = (app) => {
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const state = getState()

    const variables = {
      businessID: state.businessID,
    }

    if (app){
      variables.app = {
        eq: app
      }
    }

    const request = API.graphql({query: appCredentialByBusinessByApp, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchListItems(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const getItem = (id) => {
  console.log('getItem1',getItem)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const variables = {id}
    const request = API.graphql({query: getAppCredential, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = response => ({
  type: Types.APP_UPDATE_ITEM,
  payload: response
});

const updateItem = (item) => {
  return (dispatch, getState) => {

    const variables = {input: item}
    const request = API.graphql({query: updateAppCredential, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchUpdateItem(response))
    });
    
  }
}

const dispatchCreateItem = response => ({
  type: Types.APP_CREATE_ITEM,
  payload: response
});

const createItem = (item) => {
  return (dispatch, getState) => {

    const variables = {input: item}
    const request = API.graphql({query: createAppCredential, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchCreateItem(response))
    });
    
  }
}

const Actions = {
  getItem,
  createItem,
  updateConfig,
  updateItem,
  listItems
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions