import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

const PageHeading = ({withCreate, item, label, labels, isNew, actions}) => {

	const location = useLocation();

	const firstLevel = location.pathname.split('/')[1]

	const itemLabel = item && (label || item.name) || isNew && `New`;

	const backLink = item && `/${firstLevel}` || '/dashboard'
	const newLink = `/${firstLevel}/new`

	return (
			<div className="p-8 bg-white">
			  <div>
			    <nav class="sm:hidden">
			      <Link to={backLink} class="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 focus:outline-none focus:underline transition duration-150 ease-in-out">
			        <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
			          <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
			        </svg>
			        Back
			      </Link>
			    </nav>
			    <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">
			      <Link to="/dashboard" class="text-gray-500 hover:text-gray-700 focus:outline-none focus:underline transition duration-150 ease-in-out">
			      	<svg class="h-4 w-4 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"></path></svg>
			      </Link>
			      <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
			        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
			      </svg>
			      <Link to={`/${firstLevel}`} class="capitalize text-gray-500 hover:text-gray-700 focus:outline-none focus:underline transition duration-150 ease-in-out">
			      	{labels && labels[0] || firstLevel}
			      </Link>
				  { itemLabel && 
				  		<React.Fragment>
					  		<svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
						        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
						    </svg>
						    <Link to={`/${firstLevel}/${item && item.id || 'new'}`} class="text-gray-500 hover:text-gray-700 focus:outline-none focus:underline transition duration-150 ease-in-out">
						    	{itemLabel}
						    </Link>
						</React.Fragment>
				  }
			    </nav>
			  </div>
			  <div class="mt-2 md:flex md:items-center md:justify-between">
			    <div class="flex-1 min-w-0">
			      <h2 class="capitalize text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
			        { itemLabel || labels && labels[0] || firstLevel }
			      </h2>
			    </div>
			    <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
			      {
			      	withCreate && <span class="shadow-sm rounded-md">
				        <Link to={newLink} class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out">
				          Create
				        </Link>
				      </span>
				  }
			      {
			      	actions && actions.map(action => (
			      	  <span key={action.label} class="shadow-sm rounded-md">
				        <button onClick={action.onClick} class={ action.class || `inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out`}>
				          {action.label}
				        </button>
				      </span>
				    ))
				  }
			      {/*<span class="ml-3 shadow-sm rounded-md">
			        <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out">
			          Publish
			        </button>
			      </span>*/}
			    </div>
			  </div>
			</div>
		)
}

export default PageHeading