import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as APPACTIONS} from 'store/app/action';
import {Actions as ACTIONS} from 'store/xero/action';
import {Actions as INTEGRATIONACTIONS} from 'store/integration/action';
import PageHeading from 'components/PageHeading';
import { v4 as uuidv4 } from 'uuid';
import {ReactComponent as XeroConnectButton} from './connect-blue.svg';
import {ReactComponent as XeroDisconnectButton} from './disconnect-blue.svg';
import Select from 'react-select';


const Xero = ({
	xero,
	isConnectedToXero,
	xeroCallback,
	setTenant,
	listApps,
	updateSetting,
	replaceApp,
	id,
	apps
}) => {

	console.log('weareinXERO',xero)

  	let history = useHistory();
	const tenant = xero.tenant


	useEffect(() => {

		if (window.location.pathname == '/xero/callback'){
		  // window.location = apiUrl + window.location.pathname + window.location.search;
		  // setSession(localStorage.getItem('session'));
		  console.log('callback')
		  xeroCallback()
		} else {

		  /*if (document.referrer.substring(8,26) == "authorize.xero.com" || document.referrer.substring(8,22) == "login.xero.com"){
		    // this is from Xero do nothing
		    // window.location = localStorage.getItem('parentFrame') || 'http://theradapp.taxtaker.barefootdev.com/account';
		  } else {
		    localStorage.setItem('parentFrame',document.referrer)
		  }

		  if (window.location.search.substring(1).length){
		    localStorage.setItem('session',window.location.search.substring(1));
		    setSession(window.location.search.substring(1));
		  } else if (localStorage.getItem('session')) {
		    setSession(localStorage.getItem('session'));
		  }*/
		  isConnectedToXero && isConnectedToXero()

		  listApps()
		}
	}, [])

   /* useEffect(() => {
        xero.connected && xero.tenant && getTaxRates()
        xero.connected && xero.tenant && getAccounts()
        xero.connected && xero.tenant && getItems()
        xero.connected && xero.tenant && getOrganisationSettings()
        //   getTaxTemplateFunction()
    }, [xero.connected,xero.tenant && xero.tenant.value])*/

	if ( !xero.connected && !xero.xeroLoginUrl ){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	if ( !xero.connected && xero.xeroLoginUrl ){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<a
		            href={xero.xeroLoginUrl}
		            style={{marginTop:'10px'}}
		          >
		          	<XeroConnectButton/>
		          </a>
			</div>
		)
	}

	const selectDefaultStyle = {
	  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
	};

	const tenantOptions = xero.tenants.map(tenant => ({
			label: tenant.organisation.Name,
			value: tenant.tenantId
		}))

	console.log('tenantOptions',tenantOptions)


	return (

		
	      <div class="col-span-6 sm:col-span-4">
	        <label class="block text-sm font-medium leading-5 text-gray-700">Xero Organisation</label>
	        {/*<input id="givenName" name="givenName" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />*/}
	        <Select
	          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
	          styles={selectDefaultStyle}
	          value={tenantOptions && tenantOptions.find(item => item.value == tenant) || {value:"",label:""}}
	          onChange={(selected) => {
	              setTenant && setTenant(selected.value)
	              updateSetting && updateSetting('xeroTenant',selected.value)
	              replaceApp && replaceApp(id,apps && apps[0].id)
	            }
	          }
	          options={tenantOptions}
	        />

	        <a
	            href={xero.xeroLoginUrl}
	            style={{marginTop:'12px', fontSize: "10px", marginTop:"10px"}}
	          >
	          	Connect another Xero Organisation
	        </a>
	      </div>

		)
}


const mapStateToProps = (state) => {
	return {
	  	xero: state.xero,
	  	apps: state.apps && state.apps.filter(({app}) => app == "Xero"),
  	}
}

const mapDispatchToProps = dispatch => ({
// xeroCallback
// getTaxRates
// getAccounts
// getTrackingCategories
// getItems

  listApps: (app) => dispatch(APPACTIONS.listItems(app)),

  setTenant: (tenant) => dispatch(ACTIONS.setTenant(tenant)),
  isConnectedToXero: () => dispatch(ACTIONS.isConnectedToXero()),
  xeroCallback: () => dispatch(ACTIONS.xeroCallback()),

  updateSetting: (key,value) => dispatch(INTEGRATIONACTIONS.updateSetting(key,value)),
  replaceApp: (oldApp,newApp) => dispatch(INTEGRATIONACTIONS.replaceApp(oldApp,newApp)),
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Xero) 