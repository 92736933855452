import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

// import {Actions as ACTIONS} from 'store/integration/action';
import Xero from '../xero/Xero';
import Mindbody from '../mindbody/Mindbody';
import PageHeading from 'components/PageHeading';
import {Actions as ACTIONS} from 'store/integration/action';
import {Actions as XEROACTIONS} from 'store/xero/action';
import {Actions as MINDBODYACTIONS} from 'store/mindbody/action';
import Select from 'react-select';
import { useParams, useHistory} from "react-router";

const LocationRow = ({
	location,
	rowNo,
	updateSetting,
	xeroTaxRates,
	locationSettings,

}) => {

	const [active, setActive] = useState(locationSettings[location.Id] ? true : false)

	const selectDefaultStyle = {
	  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
	};

	const select = (taxName,taxRate) => 
		<Select
          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          styles={selectDefaultStyle}
	      value={ locationSettings && locationSettings[location.Id] && locationSettings[location.Id][taxName] && xeroTaxRates && xeroTaxRates.find(item => item.value == locationSettings[location.Id][taxName].taxCode) || {value:"",label:""}}
          
          onChange={(selected) => {
              updateSetting && updateSetting(`location[${location.Id}].${taxName}`,{taxRate, taxCode: selected.value})
            }
          }
          options={xeroTaxRates}
       	/>

	console.log('locationSettings',locationSettings)

	return (
		
		<tr className={rowNo % 2 == 1 ? "bg-white" : "bg-gray-50"}>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
              {/*<!-- On: "bg-indigo-600", Off: "bg-gray-200" -->*/}
				<span onClick={() => {
					setActive(!active)
					updateSetting(`location[${location.Id}]`,null)
				}} role="checkbox" tabindex="0" aria-checked="false" className={`${active ? "bg-indigo-600" : "bg-gray-200"} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
				  {/*<!-- On: "translate-x-5", Off: "translate-x-0" -->*/}
				  <span aria-hidden="true" className={`${active ? "translate-x-5" : "translate-x-0"} relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}>
				    {/*<!-- On: "opacity-0 ease-out duration-100", Off: "opacity-100 ease-in duration-200" -->*/}
				    <span className={`${active ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200"} absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}>
				      <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
				        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				      </svg>
				    </span>
				    {/*<!-- On: "opacity-100 ease-in duration-200", Off: "opacity-0 ease-out duration-100" -->*/}
				    <span className={`${active ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100"} absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}>
				      <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
				        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
				      </svg>
				    </span>
				  </span>
				</span>

            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
              {location.Name}
            </td>
            
            { active && 
            	<React.Fragment>
		            <td className="px-6 py-4 relative whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
		              	<span className="absolute top-0" style={{fontSize:"9px"}}>{(location.Tax1 * 100).toFixed(2)}%</span>
		              	{select("Tax1",location.Tax1)}
		            </td>
		            <td className="px-6 py-4 relative whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
		              	<span className="absolute top-0" style={{fontSize:"9px"}}>{(location.Tax2 * 100).toFixed(2)}%</span>
		              	{select("Tax2",location.Tax2)}
		            </td>
		            <td className="px-6 py-4 relative whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
			            <span className="absolute top-0" style={{fontSize:"9px"}}>{(location.Tax3 * 100).toFixed(2)}%</span>
			            {select("Tax3",location.Tax3)}
		            </td>
		            <td className="px-6 py-4 relative whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
		              	<span className="absolute top-0" style={{fontSize:"9px"}}>{(location.Tax4 * 100).toFixed(2)}%</span>
		              	{select("Tax4",location.Tax4)}
		            </td>
		            <td className="px-6 py-4 relative whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
		              	<span className="absolute top-0" style={{fontSize:"9px"}}>{(location.Tax5 * 100).toFixed(2)}%</span>
		              	{select("Tax5",location.Tax5)}
		            </td>
				</React.Fragment>
			}
            
            {/* filler so row looks complete */}
            { !active && 
            	<td colSpan="5" className="px-6 py-4 relative whitespace-no-wrap text-sm leading-5 font-medium text-gray-900"></td>
            }

        </tr>
					        	
	)
}


const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {

      	integration: state.integration,
	  	integrationApps: state.integration && state.integration.item && state.integration.item.apps,
      	businessID: state.businessID,

	  	xeroTaxRates: state.xero && state.xero.taxrates,
	  	locationSettings: state.integration && state.integration.settings && state.integration.settings.location,

  }
}

const mapDispatchToProps = dispatch => ({
  updateSetting: (key,value) => dispatch(ACTIONS.updateSetting(key,value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationRow) 
