import awsmobile from 'aws-exports';

import { API, graphqlOperation, Auth } from 'aws-amplify';

const apiName = "app";

// types of action
const Types = {
  XERO_GET_TAX_RATES: "XERO_GET_TAX_RATES",
  XERO_GET_ACCOUNTS: "XERO_GET_ACCOUNTS",
  XERO_GET_TRACKING_CATEGORIES: "XERO_GET_TRACKING_CATEGORIES",
  XERO_GET_ITEMS: "XERO_GET_ITEMS",
  XERO_UPDATE_CONNECTED: "XERO_UPDATE_CONNECTED",
  XERO_CALLBACK: "XERO_CALLBACK",
  XERO_SET_TENANT: "XERO_SET_TENANT",
  XERO_DELETE_TENANT: "XERO_DELETE_TENANT",
};

const setTenant = (value) => ({
  type: Types.XERO_SET_TENANT,
  value
});


const updateXeroConnectionStatus = response => ({
  type: Types.XERO_UPDATE_CONNECTED,
  payload: response.data
});

const updateXeroCallback = response => ({
  type: Types.XERO_CALLBACK,
  payload: response.data
});

const updateTaxRates = response => ({
  type: Types.XERO_GET_TAX_RATES,
  payload: response.data
});

const updateAccounts = response => ({
  type: Types.XERO_GET_ACCOUNTS,
  payload: response.data
});

const updateTrackingCategories = response => ({
  type: Types.XERO_GET_TRACKING_CATEGORIES,
  payload: response.data
});

const updateItems = response => ({
  type: Types.XERO_GET_ITEMS,
  payload: response.data
});

const isConnectedToXero = () => {

  return (dispatch) => {
    // let path = "/items/delegation";
    let path = "/xero/is-connected";
    let myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return entire response object instead of response.data)
      queryStringParameters: {} // OPTIONAL
    };

    API.get(apiName, path, myInit)
          .then(response => {
        dispatch(updateXeroConnectionStatus(response))
      })

  }
};

const xeroCallback = () => {

  console.log('xeroCallbackCalled');

  return (dispatch) => {
    // let path = "/items/delegation";
    let path = "/xero/callback" + window.location.search;
       let myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return entire response object instead of response.data)
      queryStringParameters: {} // OPTIONAL
    };

    //clear from url
    window.history.replaceState(null,null,'/xero');

    API.get(apiName, path, myInit)
          .then(response => {
        dispatch(updateXeroCallback(response))
      })

  }
};

const getTaxRates = (tenant) => {

  // const tenant = store.getState().Bundles.xeroApiReducer.tenant.value;

  return (dispatch) => {
    // let path = "/items/delegation";
    let path = "/xero/tax-rates";
    let myInit = {
    // OPTIONAL
    headers: {
      "xero-tenant-id": tenant
    }, // OPTIONAL
      response: true, // OPTIONAL (return entire response object instead of response.data)
      queryStringParameters: {}, // OPTIONAL
    };

    API.get(apiName, path, myInit)
          .then(response => {
        dispatch(updateTaxRates(response))
      })

  }
};

const getAccounts = (tenant) => {

  // const tenant = store.getState().Bundles.xeroApiReducer.tenant.value;

  return (dispatch) => {
    // let path = "/items/delegation";
    let path = "/xero/accounts";
    let myInit = {
    // OPTIONAL
    headers: {
      "xero-tenant-id": tenant
    }, // OPTIONAL
      response: true, // OPTIONAL (return entire response object instead of response.data)
      queryStringParameters: {}, // OPTIONAL
    };

    API.get(apiName, path, myInit)
          .then(response => {
        dispatch(updateAccounts(response))
      })

  }
};

const getItems = (tenant) => {


  return (dispatch, getState) => {

    // const tenant = getState().xero.tenant.value;

    // let path = "/items/delegation";
    let path = "/xero/items";
    let myInit = {
    // OPTIONAL
    headers: {
      "xero-tenant-id": tenant
    }, // OPTIONAL
      response: true, // OPTIONAL (return entire response object instead of response.data)
      queryStringParameters: {}, // OPTIONAL
    };

    API.get(apiName, path, myInit)
          .then(response => {
        dispatch(updateItems(response))
      })

  }
};

const getTrackingCategories = (tenant) => {

  return (dispatch, getState) => {
    // let path = "/items/delegation";
    let path = "/xero/tracking-categories";
    let myInit = {
    // OPTIONAL
    headers: {
      "xero-tenant-id": tenant
    }, // OPTIONAL
      response: true, // OPTIONAL (return entire response object instead of response.data)
      queryStringParameters: {} // OPTIONAL
    };

    API.get(apiName, path, myInit)
          .then(response => {
            console.log('response',response)
            dispatch(updateTrackingCategories(response))
          })

  }
};

/*const getOrganisationSettings = () => {

  const tenant = store.getState().Bundles.xeroApiReducer.tenant.value;

  return (dispatch) => {
    console.log('asd')
    API.graphql(graphqlOperation(
      gqlGetOrganisationSettings,
      {id:tenant}
    ))
    .then(res => {
      console.log('res',res)
          dispatch(loadOrganisationSettings(res))
          })
  }
};*/

const Actions = {
  isConnectedToXero,
  xeroCallback,
  getTaxRates,
  getAccounts,
  getTrackingCategories,
  getItems,
  setTenant,
};


export {Types, Actions}