import {Types} from "./action";
import _ from "lodash";


const defaultState = {
};

// TRACKING_CATEGORIES
// ACCOUNT_CODES
// CONNECTED_TO_XERO

const reducer = (state = defaultState, action) => {
  switch (action.type) {

    case Types.XERO_SET_TENANT: {
      let newState = _.cloneDeep(state);

      /*if (action.value && action.value.value.substring(0,4) == "http"){
        window.location.href = action.value.value;
      }*/

      newState.xero.tenant = action.value;
      // newState.selectedTaxRates = selectedTaxRates;
      return newState;
    }

    case Types.GET_SETTINGS: {
      let newState = _.cloneDeep(state);

      const settings = action.payload.getOrganisationSettings;
      newState.taxTemplate = settings.template;
      newState.organisationId = settings.id;

      const selectedTaxRates = {}
      
      console.log('getOrganisationSettings payload',action.payload)

      settings.taxes && settings.taxes.map(item=>{

        selectedTaxRates[item.key] = {
          value: item.xeroTaxId,
          label: item.key
        }
      })

      newState.selectedTaxRates = selectedTaxRates;

      const selectedAccounts = {}

      settings.accounts && settings.accounts.map(item=>{

        selectedAccounts[item.key] = {
          value: item.xeroAccountCode,
          label: item.key
        }
      })

      newState.selectedAccounts = selectedAccounts;
      
      console.log('getOrganisationSettings state',newState)

      return newState;
    }

    case Types.XERO_GET_TAX_RATES: {
      const taxrates = action.payload.data.map((taxrate)=>({
        value: taxrate.TaxType,
        label: taxrate.Name,
        // code: taxrate.TaxType,
      })) 

      let newState = _.cloneDeep(state);
      newState.xero.taxrates = taxrates;
      
      return newState;
    }

    case Types.XERO_GET_ACCOUNTS: {

      let newState = _.cloneDeep(state);
      const accounts = action.payload.data;
      newState.xero.accounts = accounts;
      
      return newState;
    }

    case Types.XERO_GET_TRACKING_CATEGORIES: {

      let newState = _.cloneDeep(state);
      const trackingCategories = action.payload.data;
      newState.xero.trackingCategories = trackingCategories;
      
      return newState;
    }

    case Types.XERO_GET_ITEMS: {

      let newState = _.cloneDeep(state);
      const items = action.payload.data.map(item => (
          {
            id: item.ItemID,
            name: item.Name,
            code: item.Code,
            description: item.Description,
          }
        ))

      newState.xero.items = items;
      return newState;
    }

    case Types.XERO_UPDATE_CONNECTED: {

      let response = action.payload;

      let newState = _.cloneDeep(state);
      newState.xero.connected = response.connected;
      newState.xero.tenants = response.tenants;
      newState.xero.xeroLoginUrl = response.url;

      console.log('newState',newState);

      return newState;
    }

    case Types.XERO_CALLBACK: {

      let response = action.payload;

      let newState = _.cloneDeep(state);
      if (response.success){
        newState.xero.connected = true;
      }
      // newState.xeroLoginUrl = response.url;

      return newState;
    }

    default:
      return state;
  }
};

export default reducer;