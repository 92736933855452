import _ from 'lodash'

import {
  getIntegrationSettings,
  integrationSettingsByBusinessbyIntegrationType,
} from 'graphql/queries'

import {
  createIntegrationSettings,
  updateIntegrationSettings,
  createIntegrationIdentifier,
  updateIntegrationIdentifier,
} from 'graphql/mutations';
       
import { API, graphqlOperation, Auth } from 'aws-amplify';
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';

// types of action
const Types = {
  INTEGRATION_GET_ITEM: "INTEGRATION_GET_ITEM",
  INTEGRATION_LIST_ITEMS: "INTEGRATION_LIST_ITEMS",
  INTEGRATION_UPDATE_ITEM: "INTEGRATION_UPDATE_ITEM",
  INTEGRATION_CREATE_ITEM: "INTEGRATION_CREATE_ITEM",
  INTEGRATION_UPDATE_SETTING: "INTEGRATION_UPDATE_SETTING",
  INTEGRATION_LOADING: "INTEGRATION_LOADING",
  INTEGRATION_REPLACE_APP: "INTEGRATION_REPLACE_APP",
  INTEGRATION_INITIALISE_SETTING: "INTEGRATION_INITIALISE_SETTING",
};

const dispatchLoading =  loading => ({
  type: Types.INTEGRATION_LOADING,
  loading
});

const dispatchGetItem = (response,copy) => ({
  type: Types.INTEGRATION_GET_ITEM,
  payload: response,
  copy
});

const dispatchListItems = response => ({
  type: Types.INTEGRATION_LIST_ITEMS,
  payload: response
});

const updateSetting = (key,value) => ({
  type: Types.INTEGRATION_UPDATE_SETTING,
  key,
  value
});

const initialiseSettings = (init) => ({
  type: Types.INTEGRATION_INITIALISE_SETTING,
  init
});

const listItems = (type) => {
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))


    const state = getState()

    const variables = {
      businessID: state.businessID,
      type: {
        eq: type
      }
    }
    const request = API.graphql({query: integrationSettingsByBusinessbyIntegrationType, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchListItems(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const getItem = (id,copy) => {
  console.log('getItem1',id,copy)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const variables = {id}
    const request = API.graphql({query: getIntegrationSettings, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response,copy))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = response => ({
  type: Types.INTEGRATION_UPDATE_ITEM,
  payload: response
});

const updateIntegrationIdentifiers = (item,externalIDs,state) => (
  Promise.all(externalIDs.map(async integrationIdentifier => {
    const exists = state.integration && state.integration.identifiers && _.find(state.integration.identifiers.items,{app: integrationIdentifier.app})

    if(exists){

      const variables = {input: {
        ...integrationIdentifier,
        id: exists.id
        // businessID: state.businessID,
        // integrationID: item.id,
      }}

      if (exists.externalID !== integrationIdentifier.externalID){
        // only update if things changed
        const updatedIdentifier = await API.graphql({query: updateIntegrationIdentifier, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
      }

    } else {

      const variables = {input: {
        ...integrationIdentifier,
        businessID: state.businessID,
        integrationID: item.id,
      }}

      const updatedIdentifier = await API.graphql({query: createIntegrationIdentifier, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    }


  }))
)

const updateItem = (item,externalIDs) => {
  return (dispatch, getState) => {

    const state = getState()
    externalIDs && updateIntegrationIdentifiers(item,externalIDs,state)

    delete item.identifiers;

    const variables = {input: item}
    const request = API.graphql({query: updateIntegrationSettings, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchUpdateItem(response))
    });
    
  }
}

const replaceApp = (oldApp, newApp) => ({
  type: Types.INTEGRATION_REPLACE_APP,
  oldApp,
  newApp
});


const dispatchCreateItem = response => ({
  type: Types.INTEGRATION_CREATE_ITEM,
  payload: response
});

const createItem = (item,externalIDs) => {
  return (dispatch, getState) => {

    const state = getState()
    externalIDs && updateIntegrationIdentifiers(item,externalIDs,state)
    delete item.identifiers;

    const variables = {input: item}
    const request = API.graphql({query: createIntegrationSettings, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchCreateItem(response))
    });
    
  }
}

const Actions = {
  getItem,
  createItem,
  updateSetting,
  updateItem,
  listItems,
  replaceApp,
  initialiseSettings
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions