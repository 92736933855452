import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/xero/action';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';


const XCart = ({xcart}) => {

	console.log('weareinXCart',xcart)

  	let history = useHistory();
	let { id } = useParams();

	return (

		<React.Fragment>
		
	      <div class="col-span-6 sm:col-span-4">
	        <label htmlFor="apiKey" class="block text-sm font-medium leading-5 text-gray-700">API Key</label>
	        <input id="apiKey" name="apiKey" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>
	      <div class="col-span-6 sm:col-span-4 mt-5">
	        <label htmlFor="siteId" class="block text-sm font-medium leading-5 text-gray-700">Domain</label>
	        <input id="domain" name="domain" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>
	      { xcart && xcart.domain && id && 
		      <span key="Back to x-Cart" class="shadow-sm rounded-md">
		        <a href={`${xcart.domain}?target=xero_admin&apiKey=${xcart.apiKey}`} class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out">
		          Back to xCart
		        </a>
		      </span>
		  }
	    </React.Fragment>

	)
}


const mapStateToProps = (state) => {
	return {
	  xcart: state.xcart
  }
}

const mapDispatchToProps = dispatch => ({
// xeroCallback
// getTaxRates
// getAccounts
// getTrackingCategories
// getItems


  // isConnectedToXero: () => dispatch(ACTIONS.isConnectedToXero()),
  // xeroCallback: () => dispatch(ACTIONS.xeroCallback()),
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(XCart) 