import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/notifications/action';

const Notification = ({notification,closeNotification}) => {

	const icon = () => {
		switch (notification && notification.icon){
			case "success": 
				return (
					<svg class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
		              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
		            </svg>
	            )
		}
	}

	const closeNotificationOnClick = () => {
		console.log('closeNotification',notification.id)
		closeNotification(notification.id);
	}

	/*x-transition:enter="transform ease-out duration-300 transition" x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0" x-transition:leave="transition ease-in duration-100" x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0" */
	return (
		<div class="inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:pb-0 sm:items-start sm:justify-end">
		  <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
		    <div class="rounded-lg shadow-xs overflow-hidden">
		      <div class="p-4">
		        <div class="flex items-start">
		          <div class="flex-shrink-0">
		            {icon()}
		          </div>
		          <div class="ml-3 w-0 flex-1 pt-0.5">
		            <p class="text-sm leading-5 font-medium text-gray-900">
		              {notification && notification.title}
		            </p>
		            <p class="mt-1 text-sm leading-5 text-gray-500">
		              {notification && notification.message}
		            </p>
		          </div>
		          <div class="ml-4 flex-shrink-0 flex">
		            <button onClick={closeNotificationOnClick} class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
		              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
		              </svg>
		            </button>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
	)
}


const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = dispatch => ({
  // addNotification: (notification,removeAfter) => dispatch(ACTIONS.addNotification(notification,removeAfter)),
  closeNotification: (id) => dispatch(ACTIONS.closeNotification(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification)