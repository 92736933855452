/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      name
      owner
      phone
      billingAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
      }
      billingEmail
      createdAt
      updatedAt
      stripeID
      goCardlessID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBusinesss = /* GraphQL */ `
  query ListBusinesss(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        phone
        billingAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
        }
        billingEmail
        createdAt
        updatedAt
        stripeID
        goCardlessID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const businessByOwner = /* GraphQL */ `
  query BusinessByOwner(
    $owner: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        phone
        billingAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
        }
        billingEmail
        createdAt
        updatedAt
        stripeID
        goCardlessID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const businessByStripeId = /* GraphQL */ `
  query BusinessByStripeId(
    $stripeID: String
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessByStripeID(
      stripeID: $stripeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        phone
        billingAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
        }
        billingEmail
        createdAt
        updatedAt
        stripeID
        goCardlessID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const businessByGoCardlessId = /* GraphQL */ `
  query BusinessByGoCardlessId(
    $goCardlessID: String
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessByGoCardlessID(
      goCardlessID: $goCardlessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        phone
        billingAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
        }
        billingEmail
        createdAt
        updatedAt
        stripeID
        goCardlessID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBusinesses = /* GraphQL */ `
  query SyncBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        owner
        phone
        billingAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
        }
        billingEmail
        createdAt
        updatedAt
        stripeID
        goCardlessID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAppCredential = /* GraphQL */ `
  query GetAppCredential($id: ID!) {
    getAppCredential(id: $id) {
      id
      name
      businessID
      userID
      config
      app
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAppCredentials = /* GraphQL */ `
  query ListAppCredentials(
    $filter: ModelAppCredentialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppCredentials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        businessID
        userID
        config
        app
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const appCredentialByBusinessByApp = /* GraphQL */ `
  query AppCredentialByBusinessByApp(
    $businessID: ID
    $app: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppCredentialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appCredentialByBusinessByApp(
      businessID: $businessID
      app: $app
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        businessID
        userID
        config
        app
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const appCredentialByUserByApp = /* GraphQL */ `
  query AppCredentialByUserByApp(
    $userID: String
    $app: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppCredentialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appCredentialByUserByApp(
      userID: $userID
      app: $app
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        businessID
        userID
        config
        app
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppCredentials = /* GraphQL */ `
  query SyncAppCredentials(
    $filter: ModelAppCredentialFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppCredentials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        businessID
        userID
        config
        app
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationSettings = /* GraphQL */ `
  query GetIntegrationSettings($id: ID!) {
    getIntegrationSettings(id: $id) {
      id
      name
      type
      businessID
      userID
      apps
      settings
      status
      createdAt
      updatedAt
      lastSync
      _version
      _deleted
      _lastChangedAt
      identifiers {
        items {
          id
          integrationID
          businessID
          app
          externalID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listIntegrationSettingss = /* GraphQL */ `
  query ListIntegrationSettingss(
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationSettingss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        businessID
        userID
        apps
        settings
        status
        createdAt
        updatedAt
        lastSync
        _version
        _deleted
        _lastChangedAt
        identifiers {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationSettingsByBusinessbyIntegrationType = /* GraphQL */ `
  query IntegrationSettingsByBusinessbyIntegrationType(
    $businessID: ID
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationSettingsByBusinessbyIntegrationType(
      businessID: $businessID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        businessID
        userID
        apps
        settings
        status
        createdAt
        updatedAt
        lastSync
        _version
        _deleted
        _lastChangedAt
        identifiers {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationSettingsByIntegrationTypeByStatus = /* GraphQL */ `
  query IntegrationSettingsByIntegrationTypeByStatus(
    $type: IntegrationType
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationSettingsByIntegrationTypeByStatus(
      type: $type
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        businessID
        userID
        apps
        settings
        status
        createdAt
        updatedAt
        lastSync
        _version
        _deleted
        _lastChangedAt
        identifiers {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIntegrationSettings = /* GraphQL */ `
  query SyncIntegrationSettings(
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        businessID
        userID
        apps
        settings
        status
        createdAt
        updatedAt
        lastSync
        _version
        _deleted
        _lastChangedAt
        identifiers {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationIdentifier = /* GraphQL */ `
  query GetIntegrationIdentifier($id: ID!) {
    getIntegrationIdentifier(id: $id) {
      id
      integrationID
      businessID
      app
      externalID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIntegrationIdentifiers = /* GraphQL */ `
  query ListIntegrationIdentifiers(
    $filter: ModelIntegrationIdentifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationIdentifiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        integrationID
        businessID
        app
        externalID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationIdentifierByIntegrationId = /* GraphQL */ `
  query IntegrationIdentifierByIntegrationId(
    $integrationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationIdentifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationIdentifierByIntegrationID(
      integrationID: $integrationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        integrationID
        businessID
        app
        externalID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationIdentifierByAppByExternalId = /* GraphQL */ `
  query IntegrationIdentifierByAppByExternalId(
    $app: IntegratedApp
    $externalID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationIdentifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationIdentifierByAppByExternalID(
      app: $app
      externalID: $externalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        integrationID
        businessID
        app
        externalID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIntegrationIdentifiers = /* GraphQL */ `
  query SyncIntegrationIdentifiers(
    $filter: ModelIntegrationIdentifierFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationIdentifiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        integrationID
        businessID
        app
        externalID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationAlert = /* GraphQL */ `
  query GetIntegrationAlert($id: ID!) {
    getIntegrationAlert(id: $id) {
      id
      alertType
      integrationID
      businessID
      subject
      message
      sent
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listIntegrationAlerts = /* GraphQL */ `
  query ListIntegrationAlerts(
    $filter: ModelIntegrationAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertType
        integrationID
        businessID
        subject
        message
        sent
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationAlertByIntegrationByAlertType = /* GraphQL */ `
  query IntegrationAlertByIntegrationByAlertType(
    $integrationID: ID
    $alertTypeCreatedAt: ModelIntegrationAlertByIntegrationByAlertTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationAlertByIntegrationByAlertType(
      integrationID: $integrationID
      alertTypeCreatedAt: $alertTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertType
        integrationID
        businessID
        subject
        message
        sent
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationAlertByIntegrationBySubject = /* GraphQL */ `
  query IntegrationAlertByIntegrationBySubject(
    $integrationID: ID
    $subjectCreatedAt: ModelIntegrationAlertByIntegrationBySubjectCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationAlertByIntegrationBySubject(
      integrationID: $integrationID
      subjectCreatedAt: $subjectCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertType
        integrationID
        businessID
        subject
        message
        sent
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationAlertByBusinessByAlertType = /* GraphQL */ `
  query IntegrationAlertByBusinessByAlertType(
    $businessID: ID
    $alertTypeCreatedAt: ModelIntegrationAlertByBusinessByAlertTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationAlertByBusinessByAlertType(
      businessID: $businessID
      alertTypeCreatedAt: $alertTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertType
        integrationID
        businessID
        subject
        message
        sent
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIntegrationAlerts = /* GraphQL */ `
  query SyncIntegrationAlerts(
    $filter: ModelIntegrationAlertFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        alertType
        integrationID
        businessID
        subject
        message
        sent
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationQueue = /* GraphQL */ `
  query GetIntegrationQueue($id: ID!) {
    getIntegrationQueue(id: $id) {
      id
      integrationID
      businessID
      sourceID
      source
      destinationID
      destination
      type
      errors
      status
      attempts
      timeSynced
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIntegrationQueues = /* GraphQL */ `
  query ListIntegrationQueues(
    $filter: ModelIntegrationQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        integrationID
        businessID
        sourceID
        source
        destinationID
        destination
        type
        errors
        status
        attempts
        timeSynced
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationQueueByIntegrationByStatusByCreatedAt = /* GraphQL */ `
  query IntegrationQueueByIntegrationByStatusByCreatedAt(
    $integrationID: ID
    $statusCreatedAt: ModelIntegrationQueueByIntegrationByStatusByCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationQueueByIntegrationByStatusByCreatedAt(
      integrationID: $integrationID
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        integrationID
        businessID
        sourceID
        source
        destinationID
        destination
        type
        errors
        status
        attempts
        timeSynced
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationQueueByIntegrationBySourceId = /* GraphQL */ `
  query IntegrationQueueByIntegrationBySourceId(
    $integrationID: ID
    $sourceID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationQueueByIntegrationBySourceID(
      integrationID: $integrationID
      sourceID: $sourceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        integrationID
        businessID
        sourceID
        source
        destinationID
        destination
        type
        errors
        status
        attempts
        timeSynced
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const integrationQueueByBusinessByCreatedAt = /* GraphQL */ `
  query IntegrationQueueByBusinessByCreatedAt(
    $businessID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationQueueByBusinessByCreatedAt(
      businessID: $businessID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        integrationID
        businessID
        sourceID
        source
        destinationID
        destination
        type
        errors
        status
        attempts
        timeSynced
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIntegrationQueues = /* GraphQL */ `
  query SyncIntegrationQueues(
    $filter: ModelIntegrationQueueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        integrationID
        businessID
        sourceID
        source
        destinationID
        destination
        type
        errors
        status
        attempts
        timeSynced
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
