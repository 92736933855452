import React, { useState, useEffect } from "react";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Actions as SUBSCRIPTIONACTIONS} from 'store/subscription/action';
import {connect} from 'react-redux';

const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {color: '#fce883'},
      '::placeholder': {color: '#87bbfd'},
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
    valid: {
      iconColor: 'green',
      color: 'green',
    },
  },
};

const CheckoutForm = ({

  stripeIntent,
  getStripeIntent,
  getStripePaymentMethods,
}) => {


  // const [sidebarOpen, setSidebarOpen] = useState(false)
  useEffect(() => {
    getStripeIntent()
  },[]);


  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });
    // 
    const clientSecret = stripeIntent

    const savedCard = await stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          // billing_details: {
          //   name: 'Jenny Rosen',
          // },
        },
      })
      // .then(function(result) {
      //   // Handle result.error or result.setupIntent
      // });

      console.log('savedCard',savedCard);

      // refresh payment methods
      getStripePaymentMethods()

    // if (error) {
    //   console.log('[error]', error);
    // } else {
    //   console.log('[PaymentMethod]', paymentMethod);
    // }
  };

  return (

      <div class="mt-5 rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
        <div>
          <form class="sm:flex sm:items-start" onSubmit={handleSubmit}>
            <div class="" style={{
              width: "100%",
              minWidth: "400px",
              maxWidth: "500px",
              marginBottom: "20px",
              background: "#fff",
              boxShadow: "0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15)",
              borderRadius: "4px",
              padding: "10px",
              border: "none",
              fontSize: 0,
            }}>
              <CardElement options={CARD_OPTIONS} />
            </div>
            
            <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
              <span class="inline-flex rounded-md shadow-sm">
                <button type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                  Save Card
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
  );
};

const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {
      business: state.business.item,
      stripeIntent: state.subscription.stripe.intent && state.subscription.stripe.intent.client_secret,
  }
}

const mapDispatchToProps = dispatch => ({
  getStripeIntent: () => dispatch(SUBSCRIPTIONACTIONS.getStripeIntent()),
  getStripePaymentMethods: () => dispatch(SUBSCRIPTIONACTIONS.getStripePaymentMethods()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutForm) 
