import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  business: {}
};

// BUSINESS_GET_ITEM
// BUSINESS_UPDATE_ITEM
// BUSINESS_CREATE_ITEM

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.BUSINESS_GET_ITEM: {

      let newState = {...state};

      newState.business.item = action.payload.data.getBusiness;

      return newState;
    }
    case Types.BUSINESS_UPDATE_ITEM: {

      let newState = {...state};

      newState.business.item = action.payload.data.updateBusiness;
      console.log('newBusinessUpdated',newState.business.item)

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;