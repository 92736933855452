import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

// import {Actions as ACTIONS} from 'store/integration/action';
import Xero from '../xero/Xero';
import XCart from '../xCart/XCart';
import PageHeading from 'components/PageHeading';

const XeroXCart = ({business, getBusiness, updateBusiness}) => {

  	// const [sidebarOpen, setSidebarOpen] = useState(false)
	/*useEffect(() => {
		console.log('id',business && business.id)
		if (!(business && business.id)){
			console.log('getBusiness')
			getBusiness()
		}
	},[]);*/

	/*if (!business){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}*/

	const headingActions = [
		{
			label: "Save",
			onClick: () => {
				console.log('timeToSave')
			}
		}
	]

	return (
		<React.Fragment>
			<PageHeading isNew={false} labels={[`XERO - xCart`]} actions={headingActions}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

				<div className="mt-10 sm:mt-0">
				  <div className="md:grid md:grid-cols-6 md:gap-6">

					<div className="bg-white shadow overflow-hidden sm:rounded-lg sm:col-span-3">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      XERO
					    </h3>
					    {/*<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
					      Personal details and application.
					    </p>*/}
					  </div>
					  <div>
					  	<div className="px-4 py-5 ">
					    	<Xero />
					    </div>
					  </div>
					</div>

					<div className="bg-white shadow overflow-hidden sm:rounded-lg sm:col-span-3">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      xCart
					    </h3>
					    {/*<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
					      Personal details and application.
					    </p>*/}
					  </div>
					  <div>
					  	<div className="px-4 py-5 ">
					    	<XCart />
					    </div>
					  </div>
					</div>
				  </div>

				</div>
			</div>
		</React.Fragment>
	)
}


const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {
      // business: state.business.item,
      // integration: state.integration && state.integration.current
  }
}

const mapDispatchToProps = dispatch => ({
  // updateBusiness: (item) => dispatch(ACTIONS.updateItem(item)),
  // getBusiness: (id) => dispatch(ACTIONS.getItem(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(XeroXCart) 
