import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  App: {}
};

// BUSINESS_GET_ITEM
// BUSINESS_UPDATE_ITEM
// BUSINESS_CREATE_ITEM

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.APP_LIST_ITEMS: {

      let newState = {...state};
      console.log('listItems',action.payload)

      newState.apps = action.payload.data.appCredentialByBusinessByApp.items;

      return newState;
    }
    case Types.APP_GET_ITEM: {

      let newState = {...state};

      newState.app = action.payload.data.getAppCredentials;

      console.log('gotItem',action.payload,newState.app)

      newState.app && newState.app.config && (newState.app.config = JSON.parse(newState.app.config))

      return newState;
    }
    case Types.APP_UPDATE_ITEM: {

      let newState = {...state};

      newState.app.item = action.payload.data.updateAppCredential;
      console.log('newAppCredentialUpdated',newState.app.item)

      return newState;
    }
    case Types.APP_CREATE_ITEM: {

      let newState = {...state};

      newState.app.item = action.payload.data.createAppCredential;
      console.log('newAppCredentialcreated',newState.app.item)

      return newState;
    }
    case Types.APP_UPDATE_CONFIG: {

      let newState = _.cloneDeep(state);

      console.log('updating integraiton config',action)


      if (action.key == 'name'){
        // update name of the config not a setting
        _.set(newState.app, action.key, action.value);
      } else {
        _.set(newState.app.config, action.key, action.value);
      }
      
      console.log('newState.app',newState.app.config,action.key)

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;