
import businessReducer from './business/reducer'
// import customerReducer from './customer/reducer'
// import serviceReducer from './service/reducer'
// import teamMemberReducer from './teamMember/reducer'
// import locationReducer from './location/reducer'
// import bookingReducer from './booking/reducer'
// import schedulerReducer from './scheduler/reducer'
import notificationReducer from './notifications/reducer'
import xeroReducer from './xero/reducer'
import mindbodyReducer from './mindbody/reducer'
import integrationReducer from './integration/reducer'
import appReducer from './app/reducer'
import tenantsReducer from './tenants/reducer'
import subscriptionReducer from './subscription/reducer'

// TODO set businessID based on info from login
const defaultState = {
  businessID: false,
  business: {},

  xero: {},
  mindbody: {
    customPaymentMethods: []
  },
  app: {
    config: {},
    item: {}
  },
  integration: {
    settings: {},
    item: {}
  },
  subscription: {
    stripe: {},
    goCardless: {}
  },
  notifications: [],
  tenants: [],
};


const reducer = (state = defaultState, action) => {

  console.log('state',defaultState)
  console.log('action',action)
  
  if (action && action.type.indexOf('BUSINESS') == 0){
    return businessReducer(state,action)
  }
  // run each reducer based on the action
  /*if (action && action.type.indexOf('CUSTOMER') == 0){
    return customerReducer(state,action)
  }
  if (action && action.type.indexOf('SERVICE') == 0){
    return serviceReducer(state,action)
  }
  if (action && action.type.indexOf('TEAMMEMBER') == 0){
    return teamMemberReducer(state,action)
  }
  if (action && action.type.indexOf('LOCATION') == 0){
    return locationReducer(state,action)
  }
  if (action && action.type.indexOf('BOOKING') == 0){
    return bookingReducer(state,action)
  }
  if (action && action.type.indexOf('SCHEDULE') == 0){
    return schedulerReducer(state,action)
  }*/


  if (action && action.type.indexOf('MINDBODY') == 0){
    return mindbodyReducer(state,action)
  }
  if (action && action.type.indexOf('XERO') == 0){
    return xeroReducer(state,action)
  }
  if (action && action.type.indexOf('APP') == 0){
    return appReducer(state,action)
  }
  if (action && action.type.indexOf('INTEGRATION') == 0){
    return integrationReducer(state,action)
  }
  if (action && action.type.indexOf('NOTIFICATION') == 0){
    return notificationReducer(state,action)
  }
  if (action && action.type.indexOf('TENANT') == 0){
    return tenantsReducer(state,action)
  }
  if (action && (action.type.indexOf('STRIPE') == 0 || action.type.indexOf('GOCARDLESS') == 0)){
    return subscriptionReducer(state,action)
  }
  
  return state
};

export default reducer;