import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/integration/action';
import {Actions as XEROACTIONS} from 'store/xero/action';
import Xero from '../xero/Xero';
import StarRez from '../starrez/StarRez';
import PageHeading from 'components/PageHeading';
import { useParams, useHistory} from "react-router";
import uuidv4 from 'uuid/v4'
import Select from 'react-select';

const XeroStarRez = ({
		integration, 
		getIntegrationSettings, 
		createIntegrationSettings, 
		updateIntegrationSettings, 
		xero, 
		starrez, 
		businessID,
		updateSetting,

		getXeroTrackingCategories,
		getXeroTaxRates,
		getXeroAccounts,

		starRezReportId,
		starRezTrackingCategoryReportID,
		xeroTrackingCategoryID,
		maxBatchSize,
		maxTransactionCount,
		syncStartDate,
		inactivityThreshold,
		xeroDocStatus,
		xeroTenant,
		name,
		status,

		banks,
		tax,
		trackingCategory1,
		trackingCategory2,
		paymentAccountCode,
		defaultSalesAccountCode,
		emailNotificationRecipient,

		xeroTrackingCategories,
		xeroAccounts,
		xeroTaxRates

	}) => {

	let { id, action } = useParams();

	const banksWithBlank = banks ? [...banks,{}] : [{}]
	const taxesWithBlank = tax ? [...tax,{}] : [{}]

	console.log('getIntegrationSettings id',id,action)

	const selectDefaultStyle = {
	  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
	};

  	// const [sidebarOpen, setSidebarOpen] = useState(false)
	useEffect(() => {
		console.log('id',integration && integration.id)
		if (!(integration && integration.id && integration.id == id) && id != 'new'){
			console.log('getIntegrationSettings')
			getIntegrationSettings(id,action == 'copy')
		}
	},[id]);

	useEffect(() => {
		xeroTenant && getXeroTrackingCategories(xeroTenant)
		xeroTenant && getXeroTaxRates(xeroTenant)
		xeroTenant && getXeroAccounts(xeroTenant)
	},[xeroTenant]);

	if (!(integration && integration.id) && id != 'new' && !(action && action == 'copy')){
		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	const xeroTrackingCategoryOptions = xeroTrackingCategories && xeroTrackingCategories.map(item => ({
		label: item.Name,
		value: item.TrackingCategoryID
	})) || []

	const xeroTrackingCategoryNameOptions = xeroTrackingCategories && xeroTrackingCategories.map(item => ({
		label: item.Name,
		value: item.Name
	})) || []

	const xeroBankAccounts = xeroAccounts && xeroAccounts.filter(account => account.Type == "BANK").map(item => ({
		label: item.Name,
		value: item.AccountID
	})) || []

	const xeroCurrentLiabilityAccounts = xeroAccounts && xeroAccounts.filter(account => account.Type == "CURRLIAB").map(item => ({
		label: item.Name,
		value: item.AccountID
	})) || []

	const xeroBankAccountCodes = xeroAccounts && xeroAccounts.filter(account => account.Type == "BANK").map(item => ({
		label: item.Name,
		value: item.Code
	})) || []

	const xeroCurrentLiabilityAccountCodes = xeroAccounts && xeroAccounts.filter(account => account.Type == "CURRLIAB").map(item => ({
		label: item.Name,
		value: item.Code
	})) || []

	console.log('xeroAccounts',xeroAccounts,'xeroTaxRates',xeroTaxRates)

	const onChange = (e) => {
		console.log('onChange',e)
	    updateSetting && updateSetting(e.target.name,e.target.value)
	}

	const inputValues = {
		'name' : {
			label: 'Integration Name',
			value: name,
			type: 'input'
		},
		'xeroDocStatus' : {
			label: 'xero Doc Status',
			value: xeroDocStatus,
			type: 'select',
			options: [
				{
					label: 'Authorised',
					value: 'AUTHORISED',
				},
				{
					label: 'Draft',
					value: 'DRAFT',
				}
			]
		},
		'starRezReportId' : {
			label: 'starRez Report Id',
			value: starRezReportId || "",
			type: 'input',
		},
		'maxBatchSize' : {
			label: 'max Batch Size',
			value: maxBatchSize || "",
			type: 'input',
		},
		'maxTransactionCount' : {
			label: 'max Transaction Count',
			value: maxTransactionCount || "",
			type: 'input',
		},
		'syncStartDate' : {
			label: 'sync Start Date',
			value: syncStartDate || "",
			type: 'input',
		},
		'inactivityThreshold' : {
			label: 'inactivity Threshold',
			value: inactivityThreshold || "",
			type: 'input',
		},
		'xeroTrackingCategoryID' : {
			label: 'Xero Room Tracking Category',
			value: xeroTrackingCategoryID,
			type: 'select',
			options: xeroTrackingCategoryOptions
		},
		'starRezTrackingCategoryReportID' : {
			label: 'StarRez Tracking Category Report ID (for Tracking Category Sync)',
			value: starRezTrackingCategoryReportID || "",
			type: 'input',
		},
		'trackingCategory1' : {
			label: 'Xero Tracking Category 1',
			value: trackingCategory1,
			type: 'select',
			options: xeroTrackingCategoryNameOptions
		},
		'trackingCategory2' : {
			label: 'Xero Tracking Category 2',
			value: trackingCategory2,
			type: 'select',
			options: xeroTrackingCategoryNameOptions
		},
		'paymentAccountCode' : {
			label: 'Payment Account Code',
			value: paymentAccountCode,
			type: 'select',
			options: [...xeroCurrentLiabilityAccountCodes,...xeroBankAccountCodes]
		},
		'defaultSalesAccountCode' : {
			label: 'Default Account Code',
			value: defaultSalesAccountCode,
			type: 'select',
			options: xeroCurrentLiabilityAccountCodes
		},
		'emailNotificationRecipient' : {
			label: 'Send Email Notifications to (comma separated)',
			value: emailNotificationRecipient || "",
			type: 'input',
		},
	}

	const headingActions = [
		{
			label: "Save",
			onClick: () => {

				if (integration && integration.id){

					const updatedIntegration = {
						...integration,
						// name: 
						type: 'XeroStarRez',
						settings: integration && JSON.stringify(integration.settings)
						// apps: [ID] # IDs of AppCredential
					}

					console.log('updatedIntegration',updatedIntegration)

					delete updatedIntegration._deleted
					delete updatedIntegration._lastChangedAt
					delete updatedIntegration.apps

					updateIntegrationSettings(updatedIntegration);
				} else {

					const updatedIntegration = {
						type: 'XeroStarRez',
                		businessID,
                		// userID: "eu-west-1:d236d6cd-157c-4c9b-9d7a-e4754a3e0452",
						id: uuidv4(),
						settings: integration && JSON.stringify(integration.settings)
						// apps: [ID] # IDs of AppCredential
					}
					console.log('updatedIntegration',updatedIntegration)
					createIntegrationSettings(updatedIntegration);

					//todo redirect to updatedIntegration.id
				}

			}
		},
		{
			label: status == "Active" ? "Disable" : "Enable",
			class: status == "Active" ? 
				"ml-5 inline-flex items-center px-4 py-2 border border-red-300 text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:text-gray-100 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-red-50 transition duration-150 ease-in-out"
				 :
				"ml-5 inline-flex items-center px-4 py-2 border border-green-300 text-sm leading-5 font-medium rounded-md text-white bg-green-500 hover:text-gray-100 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-green-50 transition duration-150 ease-in-out",
			onClick: () => {

				if (integration && integration.id){

					const updatedIntegration = {
						...integration,
						status: status == "Active" ? "Disabled" : "Active",
						// name: 
						type: 'XeroStarRez',
						settings: integration && JSON.stringify(integration.settings)
						// apps: [ID] # IDs of AppCredential
					}

					console.log('updatedIntegration',updatedIntegration)

					delete updatedIntegration._deleted
					delete updatedIntegration._lastChangedAt
					delete updatedIntegration.apps

					updateIntegrationSettings(updatedIntegration);
				}

			}
		}
	]

	return (
		<React.Fragment>
			<PageHeading isNew={false} labels={[`XERO - StarRez`,name]} actions={headingActions} item={integration}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

				<div className="mt-10 sm:mt-0">
				  <div className="md:grid md:grid-cols-6 md:gap-6">

					<div className="bg-white shadow sm:rounded-lg sm:col-span-3">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      XERO
					    </h3>
					    {/*<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
					      Personal details and application.
					    </p>*/}
					  </div>
					  <div>
					  	<div className="px-4 py-5 ">
					    	<Xero />
					    </div>
					  </div>
					</div>

					<div className="bg-white shadow sm:rounded-lg sm:col-span-3">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      StarRez
					    </h3>
					    {/*<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
					      Personal details and application.
					    </p>*/}
					  </div>
					  <div>
					  	<div className="px-4 py-5 ">
					    	<StarRez />
					    </div>
					  </div>
					</div>
				  </div>

					<div className="bg-white shadow sm:rounded-lg sm:col-span-6 mt-6">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      Configuration
					    </h3>
					  </div>
					  <div>
					  	<div className="px-4 py-5 ">
						  	{
						  		Object.keys(inputValues).map(fieldName => (
						  			<div class="col-span-6 sm:col-span-4 mb-4">
							    		<label htmlFor={fieldName} class="block text-sm font-medium leading-5 text-gray-700">{inputValues[fieldName].label}</label>
						  				{	inputValues[fieldName].type == 'input' ? 
								    			<input id={fieldName} name={fieldName} onChange={onChange} value={inputValues[fieldName].value} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
											    : 	
											    <Select
										          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
										          styles={selectDefaultStyle}
										          value={inputValues[fieldName].options.find(item => item.value == inputValues[fieldName].value) || {value:"",label:""}}
										          onChange={(selected) => {
										              updateSetting && updateSetting(fieldName,selected.value)
										            }
										          }
										          options={inputValues[fieldName].options}
										        />
										}
								    </div>
						  		))
						  	}
					    </div>
					  </div>

					</div>



					<div className="bg-white shadow sm:rounded-lg sm:col-span-6 mt-6 mt-6">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      Bank Accounts
					    </h3>
					  </div>
					  <div>
					  	<div className="px-4 py-5 md:grid md:grid-cols-6 md:gap-6">
				  			<div class="col-span-3 sm:col-span-3">
				  				<label>StarRez Payment Method</label>
				  			</div>
				  			<div class="col-span-3 sm:col-span-3">
				  				<label>Xero Bank Account</label>
				  			</div>
						  	{
						  		banksWithBlank.map((bank,index) => (
						  			<React.Fragment>
							  			<div class="col-span-3 sm:col-span-3">
									    	<input name={`banks[${index}].paymentMethod`} onChange={onChange} value={(banksWithBlank && banksWithBlank[index] && banksWithBlank[index].paymentMethod) || ""} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
									    </div>
							  			<div class="col-span-3 sm:col-span-3">
										   	<Select
									          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									          styles={selectDefaultStyle}
										      value={ banksWithBlank && banksWithBlank[index] && xeroBankAccounts && xeroBankAccounts.find(item => item.value == banksWithBlank[index].bank) || {value:"",label:""}}
									          
									          onChange={(selected) => {
									              updateSetting && updateSetting(`banks[${index}].bank`,selected.value)
									            }
									          }
									          options={xeroBankAccounts}
									        />
									    </div>
									</React.Fragment>
						  		))
						  	}
					    </div>
					  </div>
					</div>
					
					<div className="bg-white shadow sm:rounded-lg sm:col-span-6 mt-6 mt-6">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      Taxes Accounts
					    </h3>
					  </div>
					  <div>
					  	<div className="px-4 py-5 md:grid md:grid-cols-6 md:gap-6">
				  			<div class="col-span-3 sm:col-span-3">
				  				<label>StarRez Tax Rate</label>
				  			</div>
				  			<div class="col-span-3 sm:col-span-3">
				  				<label>Xero Tax</label>
				  			</div>
						  	{
						  		taxesWithBlank.map((bank,index) => (
						  			<React.Fragment>
							  			<div class="col-span-3 sm:col-span-3">
									    	<input name={`tax[${index}].rate`} onChange={onChange} value={(taxesWithBlank && taxesWithBlank[index] && taxesWithBlank[index].rate) || ""} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
									    </div>
							  			<div class="col-span-3 sm:col-span-3">
										   	<Select
									          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									          styles={selectDefaultStyle}
										      value={ taxesWithBlank && taxesWithBlank[index] && xeroTaxRates && xeroTaxRates.find(item => item.value == taxesWithBlank[index].xeroTaxID) || {value:"",label:""}}
									          
									          onChange={(selected) => {
									              updateSetting && updateSetting(`tax[${index}].xeroTaxID`,selected.value)
									            }
									          }
									          options={xeroTaxRates}
									        />
									    </div>
									</React.Fragment>
						  		))
						  	}
					    </div>
					  </div>
					</div>

				</div>
			</div>
		</React.Fragment>
	)
}


const mapStateToProps = (state) => {
  console.log('state',state && state.integration)
    return {
      	integration: state.integration,
      	businessID: state.businessID,

      	banks: state.integration && state.integration.settings && state.integration.settings.banks,
      	tax: state.integration && state.integration.settings && state.integration.settings.tax,

	  	status: state.integration && state.integration.status,
	  	starRezReportId: state.integration && state.integration.settings && state.integration.settings.starRezReportId,
	  	starRezTrackingCategoryReportID: state.integration && state.integration.settings && state.integration.settings.starRezTrackingCategoryReportID,
	  	xeroTrackingCategoryID: state.integration && state.integration.settings && state.integration.settings.xeroTrackingCategoryID,
	  	trackingCategory1: state.integration && state.integration.settings && state.integration.settings.trackingCategory1,
	  	trackingCategory2: state.integration && state.integration.settings && state.integration.settings.trackingCategory2,
	  	paymentAccountCode: state.integration && state.integration.settings && state.integration.settings.paymentAccountCode,
	  	defaultSalesAccountCode: state.integration && state.integration.settings && state.integration.settings.defaultSalesAccountCode,
	  	maxBatchSize: state.integration && state.integration.settings && state.integration.settings.maxBatchSize,
	  	maxTransactionCount: state.integration && state.integration.settings && state.integration.settings.maxTransactionCount,
	  	syncStartDate: state.integration && state.integration.settings && state.integration.settings.syncStartDate,
	  	inactivityThreshold: state.integration && state.integration.settings && state.integration.settings.inactivityThreshold,
	  	xeroDocStatus: state.integration && state.integration.settings && state.integration.settings.xeroDocStatus,
	  	emailNotificationRecipient: state.integration && state.integration.settings && state.integration.settings.emailNotificationRecipient,
	  	xeroTenant: state.integration && state.integration.settings && state.integration.settings.xeroTenant,
	  	name: state.integration && state.integration.settings && state.integration.name,
	  	
	  	xeroTrackingCategories: state.xero && state.xero.trackingCategories,
	  	xeroAccounts: state.xero && state.xero.accounts,
	  	xeroTaxRates: state.xero && state.xero.taxrates,
  }
}

const mapDispatchToProps = dispatch => ({
  updateIntegrationSettings: (item) => dispatch(ACTIONS.updateItem(item)),
  createIntegrationSettings: (item) => dispatch(ACTIONS.createItem(item)),
  getIntegrationSettings: (id,copy) => dispatch(ACTIONS.getItem(id,copy)),
  updateSetting: (key,value) => dispatch(ACTIONS.updateSetting(key,value)),
  getXeroTrackingCategories: (tenant) => dispatch(XEROACTIONS.getTrackingCategories(tenant)),
  getXeroTaxRates: (tenant) => dispatch(XEROACTIONS.getTaxRates(tenant)),
  getXeroAccounts: (tenant) => dispatch(XEROACTIONS.getAccounts(tenant)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(XeroStarRez) 
