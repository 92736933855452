import React from 'react';
import logo from './logo.svg';
import './App.css';
import "./styles/app.css";
import Wrapper from "./components/Wrapper";
import BusinessSettings from "./components/business/BusinessSettings"
// import Bookings from "./components/booking/Bookings"
// import Services from "./components/services/Services"
// import Service from "./components/services/Service"
// import TeamMembers from "./components/teamMember/TeamMembers"
// import TeamMember from "./components/teamMember/TeamMember"
// import Locations from "./components/location/Locations"
// import Location from "./components/location/Location"
// import Customers from "./components/customer/Customers"
// import Customer from "./components/customer/Customer"
import Xero from "./components/xero/Xero"
import XeroMindbody from "./components/xeroMindbody/XeroMindbody"
import XeroXCart from "./components/xeroXCart/XeroXCart"
import XeroStarRez from "./components/xeroStarrez/XeroStarRez"
import XeroDash from "./components/xeroDash/XeroDash"
import Subscriptions from "./components/subscription/Subscriptions"

import {connect} from 'react-redux';

import ListIntegrations from "./components/listIntegrations/ListIntegrations"

import Amplify, { Auth } from 'aws-amplify';
import Analytics from '@aws-amplify/analytics';
// import PushNotification from '@aws-amplify/pushnotification';
import awsconfig from './aws-exports';
import { SelectMFAType } from 'aws-amplify-react';
import {Actions as XEROACTIONS} from 'store/xero/action';

import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";


Amplify.configure({
  ...awsconfig,
  "oauth": {
      "domain": "login.integr8a.com",
      // "domain": "integr8aapp2cc11aee-2cc11aee-prod.auth.eu-west-1.amazoncognito.com",
      "scope": ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      "redirectSignIn": "https://app.integr8a.com/",
      "redirectSignOut": "https://app.integr8a.com/",
      "responseType": "code",
  }
});

const usePageViews = () => {
  let location = useLocation();
  // React.useEffect(
  //   () => {
  //     ga.send(["pageview", location.pathname]);
  //   }, 
  //   [location]
  // );
}

const App = () => {
  usePageViews()

  // PushNotification need to work with Analytics
  Analytics.configure(awsconfig);



  return (
    <div className="App">
      <Wrapper>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route exact path="/dashboard">
            DASHBOARD HERE
          </Route>
          <Route path="/xero" component={Xero}/>
          <Route 
            path="/xero-mindbody" 
            exact 
            render={(props) => <ListIntegrations {...props} type="XeroMindBody" label="Xero - MindBody" />}
            />
          <Route path="/xero-mindbody/:id" component={XeroMindbody}/>
          <Route 
            path="/xero-xcart" 
            exact 
            render={(props) => <ListIntegrations {...props} type="XeroXCart" label="Xero - XeroXCart" />}
            />
          <Route path="/xero-xcart/:id" component={XeroXCart}/>
          <Route 
            path="/xero-starrez" 
            exact 
            render={(props) => <ListIntegrations {...props} type="XeroStarRez" label="Xero - StarRez" />}
            />
          <Route path="/xero-starrez/:id/:action?" component={XeroStarRez}/>
          <Route 
            path="/xero-dash" 
            exact 
            render={(props) => <ListIntegrations {...props} type="XeroDash" label="Xero - DASH" />}
            />
          <Route path="/xero-dash/:id/:action?" component={XeroDash}/>
          <Route path="/subscription" component={Subscriptions}/>
          {/*<Route exact path="/bookings">
                      <Bookings/>
                    </Route>
                    <Route exact path="/services">
                      <Services/>
                    </Route>
                    <Route path="/services/:id" component={Service}/>
          
                    <Route exact path="/location" component={Locations}/>
                    <Route path="/location/:id" component={Location}/>
          
                    <Route exact path="/customer" component={Customers}/>
                    <Route path="/customer/:id" component={Customer}/>
          
                    <Route exact path="/team" component={TeamMembers}/>
                    <Route path="/team/:id" component={TeamMember}/>*/}
          <Route exact path="/settings">
            <BusinessSettings/>
          </Route>
        </Switch>
      </Wrapper>
    </div>
  );
}

const AppWithAuthenticator = withAuthenticator(App, false, [
  /*<div>
    <a href="#" onClick={() => Auth.federatedSignIn({ customProvider: "Xero"})} className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700">
      Sign in with Xero
    </a>
    
  </div>,*/
  <SignIn/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
]);


// The below part is done due to a clash we have with the token generation of Xero with Amplify
const XeroCallbackWrapper = ({
  xeroCallback,
  xeroConnected
}) => {


  React.useEffect( () => {

    if (window.location.pathname == '/xero/callback'){
      // window.location = apiUrl + window.location.pathname + window.location.search;
      // setSession(localStorage.getItem('session'));
      console.log('callback')
      xeroCallback()
    } 
  }, [])

  // if (window.location.pathname == '/xero/callback'){
  //   return <div>Connecting with Xero...</div>
  // } else {
    return (<AppWithAuthenticator/>)
  // }

}

const mapStateToProps = (state) => ({
      xeroConnected: state.xero && state.xero.connected,
      // apps: state.apps && state.apps.filter(({app}) => app == "Xero"),
  })

const mapDispatchToProps = dispatch => ({
  xeroCallback: () => dispatch(XEROACTIONS.xeroCallback()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(XeroCallbackWrapper) 


