import {
          getBusiness,
       } from 'graphql/queries'

import {
  createBusiness,
  updateBusiness,
} from 'graphql/mutations';


import {
  Actions as SUBSCRIPTIONACTIONS,
} from '../subscription/action.js';

       
import { API, graphqlOperation, Auth } from 'aws-amplify';
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';

// types of action
const Types = {
  BUSINESS_GET_ITEM: "BUSINESS_GET_ITEM",
  BUSINESS_UPDATE_ITEM: "BUSINESS_UPDATE_ITEM",
  BUSINESS_CREATE_ITEM: "BUSINESS_CREATE_ITEM",
  BUSINESS_LOADING: "BUSINESS_LOADING",
};

const dispatchLoading =  loading => ({
  type: Types.BUSINESS_LOADING,
  loading
});

const dispatchGetItem = response => ({
  type: Types.BUSINESS_GET_ITEM,
  payload: response
});

const getItem = (id) => {
  console.log('getItem1',getItem)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const state = getState()

    const variables = {id: state.businessID}
    const request = API.graphql({query: getBusiness, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = response => ({
  type: Types.BUSINESS_UPDATE_ITEM,
  payload: response
});

const updateItem = (item) => {
  return (dispatch, getState) => {

    const variables = {input: item}
    const request = API.graphql({query: updateBusiness, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    const state = getState()

    if (!state.business.item.stripeID){
      dispatch(SUBSCRIPTIONACTIONS.createStripeCustomer())
    }

    request.then((response) => {
        dispatch(dispatchUpdateItem(response))
    });
    
  }
}

const dispatchCreateItem = response => ({
  type: Types.BUSINESS_CREATE_ITEM,
  payload: response
});

const createItem = (item) => {
  return (dispatch, getState) => {

    const variables = {input: item}
    const request = API.graphql({query: createBusiness, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchGetItem(response))
    });
    
  }
}

const Actions = {
  getItem,
  // createItem,
  updateItem,
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions