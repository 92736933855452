import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/business/action';


const BusinessDetails = ({business, updateBusiness}) => {

	const { register, handleSubmit, watch, errors } = useForm()
	const onSubmit = data => {
		console.log(data)

		const item = {
			id: business.id,
			_version: business._version,
			name: data.name,
			billingEmail: data.billingEmail
		}
		console.log(item)

		updateBusiness(item)
	}

	return (
			<div className="md:grid md:grid-cols-3 md:gap-6">
			    <div className="md:col-span-1">
			      <div className="px-4 sm:px-0">
			        <h3 className="text-lg font-medium leading-6 text-gray-900">Basic Business Details</h3>
			        <p className="mt-1 text-sm leading-5 text-gray-500">
			          This information will be displayed publicly.
			        </p>
			      </div>
			    </div>
			    <div className="mt-5 md:mt-0 md:col-span-2">
			      <form onSubmit={handleSubmit(onSubmit)}>
			        <div className="shadow sm:rounded-md sm:overflow-hidden">
			          <div className="px-4 py-5 bg-white sm:p-6">
			            <div className="grid grid-cols-6 gap-6">

							<div className="col-span-6 sm:col-span-4">
								<label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Business Name</label>
								<input id="name" name="name" defaultValue={business.name} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
							</div>

							<div className="col-span-6 sm:col-span-4">
								<label htmlFor="billingEmail" className="block text-sm font-medium leading-5 text-gray-700">Email address</label>
								<input id="billingEmail" name="billingEmail"  defaultValue={business.billingEmail} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
							</div>

				              <div className="col-span-6 sm:col-span-4">
				                <label htmlFor="website" className="block text-sm font-medium leading-5 text-gray-700">
				                  Website
				                </label>
				                <div className="mt-1 flex rounded-md shadow-sm">
				                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
				                    https://
				                  </span>
				                  <input id="website" name="website" ref={register} className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="www.example.com" />
				                </div>
				              </div>

				            {/*<div className="mt-6">
				              <label htmlFor="about" className="block text-sm leading-5 font-medium text-gray-700">
				                About
				              </label>
				              <div className="rounded-md shadow-sm">
				                <textarea id="about" rows="3" className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="you@example.com"></textarea>
				              </div>
				              <p className="mt-2 text-sm text-gray-500">
				                Brief description for your profile. URLs are hyperlinked.
				              </p>
				            </div>*/}

			          	</div>
			          </div>
			          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
			            <span className="inline-flex rounded-md shadow-sm">
			              <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
			                Save
			              </button>
			            </span>
			          </div>
			        </div>
			      </form>
			    </div>
			  </div>
		)
}


const mapStateToProps = (state) => {
    return {
      business: state.business.item,
  }
}

const mapDispatchToProps = dispatch => ({
  updateBusiness: (item) => dispatch(ACTIONS.updateItem(item)),
  getBusiness: (id) => dispatch(ACTIONS.getItem(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessDetails) 