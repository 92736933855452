import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/integration/action';
import {Actions as XEROACTIONS} from 'store/xero/action';
import Xero from '../xero/Xero';
import StarRez from '../starrez/StarRez';
import PageHeading from 'components/PageHeading';
import uuidv4 from 'uuid/v4'
import Select from 'react-select';
import { format } from 'date-fns'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

const ListIntegrations = ({
		businessID,
		integrations, 
		listIntegrations,
		history,
  		type,
  		label,
	}) => {


  	const location = useLocation();


	const selectDefaultStyle = {
	  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
	};

  	// const [sidebarOpen, setSidebarOpen] = useState(false)
	useEffect(() => {
		console.log('getIntegrationSettings')
		listIntegrations(type)
	},[type]);
/*
	if (true){
		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}*/

	const headingActions = [
		{
			label: "Add New",
			onClick: () => {
				// needs to be a link
				history.push(`${location.pathname}/new`)
			}
		}
	]

	return (
		<React.Fragment>
			<PageHeading isNew={false} labels={[label]} actions={headingActions}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

				<div className="mt-10 sm:mt-0">

				  	<div class="flex flex-col">
					  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					    <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
					      <table class="min-w-full">
					        <thead>
					          <tr>
					            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					              Name
					            </th>
					            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					              Last Updated
					            </th>
					            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					              Last Sync
					            </th>
					            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
					          </tr>
					        </thead>
					        <tbody>
					        	{integrations && integrations.map(integration => (
					        		<tr key={integration.id} class="bg-white">
							            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
							              {integration.name}
							            </td>
							            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
							              { format(integration._lastChangedAt,"d MMMM yyyy H:mm")}
							            </td>
							            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
							              { format(integration._lastChangedAt,"d MMMM yyyy H:mm")}
							            </td>
							            <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
											<Link to={`${location.pathname}/${integration.id}/copy`} className="text-indigo-600 hover:text-indigo-900">
							             		Copy
							             	</Link>
											<Link to={`${location.pathname}/${integration.id}`} className="text-indigo-600 hover:text-indigo-900">
							             		Edit
							             	</Link>
							            </td>
							        </tr>
					        	))}
					        </tbody>
					      </table>
					    </div>
					  </div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
  console.log('state',state && state.integration)
    return {
      	integrations: state.integrations,
      	businessID: state.businessID,
  }
}

const mapDispatchToProps = dispatch => ({
  listIntegrations: (type) => dispatch(ACTIONS.listItems(type)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListIntegrations) 
