import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/xero/action';
import {Actions as INTEGRATIONACTIONS} from 'store/integration/action';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';


// Save App Credentials

/*
              const input = {
                id: xeroCredentials[0].id,
                _version: xeroCredentials[0]._version,
                config: JSON.stringify({ // JSON.parse(
                  token: {
                    accessToken: String(token.accessToken),
                    refreshToken: String(token.refreshToken),
                    expires: String(token.expires)
                  },
                  tenants: cleanDeep(tenants)
                })
              }
              */

const StarRez = ({starrez,updateSetting,username,password,url}) => {

	console.log('weareinStarRez',username,password,url)

  	let history = useHistory();
	let { id } = useParams();

	const onChange = (e) => {
		console.log('onChange',e)
	    updateSetting && updateSetting(`starrez${e.target.name}`,e.target.value)
	}

	return (

		<React.Fragment>
		
	      <div class="col-span-6 sm:col-span-4 mb-4">
	        <label htmlFor="username" class="block text-sm font-medium leading-5 text-gray-700">Username</label>
	        <input id="username" name="Username" onChange={onChange} value={username || ""} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>
	      <div class="col-span-6 sm:col-span-4 mb-4">
	        <label htmlFor="password" class="block text-sm font-medium leading-5 text-gray-700">Password</label>
	        <input id="password" name="Password" onChange={onChange} value={password || ""} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>
	      <div class="col-span-6 sm:col-span-4 mb-4">
	        <label htmlFor="url" class="block text-sm font-medium leading-5 text-gray-700">API URL</label>
	        <input id="url" name="Url" onChange={onChange} value={url || ""} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>
	      {/*<div class="col-span-6 sm:col-span-4 mt-5">
	        <label htmlFor="reportId" class="block text-sm font-medium leading-5 text-gray-700">Report ID</label>
	        <input id="reportId" name="reportId" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>*/}
	      {/*<div class="col-span-6 sm:col-span-4 mt-5">
	        <label htmlFor="trackingCategoryId" class="block text-sm font-medium leading-5 text-gray-700">Report ID</label>
	        <input id="trackingCategoryId" name="trackingCategoryId" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	      </div>*/}
	    </React.Fragment>

	)
}


const mapStateToProps = (state) => {
	console.log('state.integration',state.integration)
	return {
	  	starrez: state.starrez,
	  	// integration: state.integration,
	  	username: state.integration && state.integration.settings && state.integration.settings['starrezUsername'],
	  	password: state.integration && state.integration.settings && state.integration.settings['starrezPassword'],
	  	url: state.integration && state.integration.settings && state.integration.settings['starrezUrl'],
  	}
}

const mapDispatchToProps = dispatch => ({
// xeroCallback
// getTaxRates
// getAccounts
// getTrackingCategories
// getItems


  // isConnectedToXero: () => dispatch(ACTIONS.isConnectedToXero()),
  // xeroCallback: () => dispatch(ACTIONS.xeroCallback()),
  updateSetting: (key,value) => dispatch(INTEGRATIONACTIONS.updateSetting(key,value)),
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StarRez) 