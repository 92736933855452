import awsmobile from 'aws-exports';

import { API, graphqlOperation, Auth } from 'aws-amplify';

const apiName = "app";

// types of action
const Types = {
  MINDBODY_GET_LOCATIONS: "MINDBODY_GET_LOCATIONS",
  MINDBODY_GET_SERVICES: "MINDBODY_GET_SERVICES",
  MINDBODY_GET_CUSTOM_PAYMENT_METHODS: "MINDBODY_GET_CUSTOM_PAYMENT_METHODS",
};

const updateLocations = response => ({
  type: Types.MINDBODY_GET_LOCATIONS,
  payload: response.data
});

const getLocations = () => {

  return (dispatch,getState) => {

    const state = getState()

    const integrationApps = state.integration.apps
    const apps = state.apps
 
    const mindBodyApp = apps && integrationApps && apps
      .filter(({app}) => app == "MindBody")
      .find(({id}) => integrationApps.includes(id) )

    if (mindBodyApp){

      // let path = "/items/delegation";
      let path = "/mindbody/locations";
      let myInit = {
        response: true, // OPTIONAL (return entire response object instead of response.data)
        queryStringParameters: {
          appID: mindBodyApp.id
        }
      };

      API.get(apiName, path, myInit)
            .then(response => {
          dispatch(updateLocations(response))
        })
    }

  }
};


const updateServices = response => ({
  type: Types.MINDBODY_GET_SERVICES,
  payload: response.data
});

const getServices = () => {

  return (dispatch,getState) => {

    const state = getState()

    const integrationApps = state.integration.apps
    const apps = state.apps
 
    const mindBodyApp = apps && integrationApps && apps
      .filter(({app}) => app == "MindBody")
      .find(({id}) => integrationApps.includes(id) )

    if (mindBodyApp){
      // let path = "/items/delegation";
      let path = "/mindbody/services";
      let myInit = {
        response: true, // OPTIONAL (return entire response object instead of response.data)
        queryStringParameters: {
          appID: mindBodyApp.id
        }
      };

      API.get(apiName, path, myInit)
            .then(response => {
          dispatch(updateServices(response))
        })
    }
  }
};


const updateCustomPaymentMethods = response => ({
  type: Types.MINDBODY_GET_CUSTOM_PAYMENT_METHODS,
  payload: response.data
});

const getCustomPaymentMethods = () => {

  return (dispatch,getState) => {

    const state = getState()

    const integrationApps = state.integration.apps
    const apps = state.apps
 
    const mindBodyApp = apps && integrationApps && apps
      .filter(({app}) => app == "MindBody")
      .find(({id}) => integrationApps.includes(id) )

    if (mindBodyApp){
      // let path = "/items/delegation";
      let path = "/mindbody/customPaymentMethods";
      let myInit = {
        response: true, // OPTIONAL (return entire response object instead of response.data)
        queryStringParameters: {
          appID: mindBodyApp.id
        }
      };

      API.get(apiName, path, myInit)
            .then(response => {
          dispatch(updateCustomPaymentMethods(response))
        })
    }
  }
};




const Actions = {
  getLocations,
  getServices,
  getCustomPaymentMethods,
};


export {Types, Actions}