import React, { useState, useEffect } from "react";
import MainMenu from "./MainMenu";
import Notifications from "components/notifications/Notifications"
import Tenants from "components/tenant/Tenants"

import {Actions as ACTIONS} from 'store/business/action';
import {connect} from 'react-redux';
import { Auth } from 'aws-amplify';


const Wrapper = ({children,businessID,business,getBusiness,userDetails}) => {

	const signOut = async() => {
	    try {
	        await Auth.signOut({ global: true });
	        window.location.reload(); // ensure app data is cleared
	    } catch (error) {
	        console.log('error signing out: ', error);
	    }
	}


  	// const [sidebarOpen, setSidebarOpen] = useState(false)
	useEffect(() => {
		console.log('id',business && business.id)
		if (businessID && !(business && business.id)){
			getBusiness()
		}
	},[businessID]);

  	const [sidebarOpen, setSidebarOpen] = useState(false)

	return (
		<div class="h-screen flex overflow-hidden bg-gray-100">
		   {/*Off-canvas menu for mobile */}
		  <div class="md:hidden" style={{display: sidebarOpen ? 'block' : 'none'}}>
		    <div onClick={() => {setSidebarOpen(false)}} class="fixed inset-0 z-30 transition-opacity ease-linear duration-300" style={{display: sidebarOpen ? 'block' : 'none'}}>
		      <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
		    </div>
		    <div class="fixed inset-0 flex z-40">
		      <div class="flex-1 flex flex-col max-w-xs w-full bg-gray-800 transform ease-in-out duration-300 " style={{display: sidebarOpen ? 'block' : 'none'}}>
		        <div class="absolute top-0 right-0 -mr-14 p-1">
		          <button onClick={() => {setSidebarOpen(false)}} class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" style={{display: sidebarOpen ? 'block' : 'none'}}>
		            <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
		              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
		            </svg>
		          </button>
		        </div>
		        <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
		          <div class="flex-shrink-0 flex items-center px-4">
		            <img class="h-8 w-auto" src="/img/logos/workflow-logo-on-dark.svg" alt="Workflow" />
		          </div>
		          <nav class="mt-5 px-2">
		            <MainMenu/>
		          </nav>
		        </div>
		        <div class="flex-shrink-0 flex bg-gray-700 p-4">
		          <a href="#" class="flex-shrink-0 group block">
	                <p class="text-base leading-6 font-medium text-white">
	                  Sign Out
	                </p>
		            {/*<div class="flex items-center">
		              <div>
		                <img class="inline-block h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
		              </div>
		              <div class="ml-3">
		                <p class="text-base leading-6 font-medium text-white">
		                  Tom Cook
		                </p>
		                <p class="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150">
		                  View profile
		                </p>
		              </div>
		            </div>*/}
		          </a>
		        </div>
		      </div>
		      <div class="flex-shrink-0 w-14">
		         {/*Force sidebar to shrink to fit close icon*/} 
		      </div>
		    </div>
		  </div>

		   {/*Static sidebar for desktop */}
		  <div class="hidden md:flex md:flex-shrink-0">
		    <div class="flex flex-col w-64 bg-gray-800">
		      <div class="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
		        <div class="flex items-center flex-shrink-0 px-4">
		          <img class="h-8 w-auto" src="/img/logos/workflow-logo-on-dark.svg" alt="Workflow" />
		        </div>

		          {/* tenant info starts here */}
		          	<Tenants/>
		          {/* tenant info finishes here */}

		         {/*Sidebar component, swap this element with another sidebar if you like */}
		        <nav class="mt-5 flex-1 px-2 bg-gray-800">
		          <MainMenu/>
		        </nav>
		      </div>
		      
		        <div className="flex-shrink-0 flex bg-gray-700 p-4">
		          <a href="#" className="flex-shrink-0 group block">
		            <div className="flex items-center">
		              <div>
		              	{/*
	                		teamMember && teamMember.photo ? 
	                			<img className="inline-block h-10 w-10 rounded-full" src={`https://${teamMember.photo.bucket}.s3-eu-west-1.amazonaws.com/public/${teamMember.photo.key}`}/>
	                			:
			                  <img className="inline-block h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
	                	*/}
		              </div>
		              <div className="ml-3">
			              <p className="text-sm leading-5 font-medium text-white">
			                {userDetails ? userDetails.attributes && userDetails.attributes.given_name ? `${userDetails.attributes.given_name} ${userDetails.attributes.family_name ? userDetails.attributes.family_name : ''}` : `${userDetails.attributes && userDetails.attributes.email}` : ``}
			              </p>
			              <p className="text-xs leading-4 font-medium text-gray-400 group-hover:text-gray-300 group-focus:underline transition ease-in-out duration-150">
			                <a onClick={signOut}>Sign Out</a>
			              </p>
			          </div>
		            </div>
		          </a>
		        </div>
		    </div>
		  </div>
		  <div class="flex flex-col w-0 flex-1 overflow-hidden">
		    <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
		      <button onClick={() => {setSidebarOpen(true)}} class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
		        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
		          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
		        </svg>
		      </button>
		    </div>
		    <main class="flex-1 relative z-0 overflow-y-auto pb-6 pt-0 focus:outline-none" tabindex="0"  x-init="$el.focus()">
        		<Notifications/>
		      	{businessID && children}
		    </main>
		  </div>
		</div>
	)
}

const mapStateToProps = (state) => {
    return {
      	businessID: state.businessID,
      	business: state.business.item,
      	userDetails: state.userDetails,
  	}
}
const mapDispatchToProps = dispatch => ({
  updateBusiness: (item) => dispatch(ACTIONS.updateItem(item)),
  getBusiness: (id) => dispatch(ACTIONS.getItem(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper) 