import {Types} from "./action";
import _ from "lodash";


const defaultState = {
};

// TRACKING_CATEGORIES
// ACCOUNT_CODES
// CONNECTED_TO_XERO

const reducer = (state = defaultState, action) => {
  switch (action.type) {


    case Types.MINDBODY_GET_LOCATIONS: {

      const newState = {...state};

      newState.mindbody.locations = action.payload;
      
      return newState;
    }

    case Types.MINDBODY_GET_SERVICES: {

      const newState = {...state};

      newState.mindbody.services = action.payload;
      
      return newState;
    }

    case Types.MINDBODY_GET_CUSTOM_PAYMENT_METHODS: {

      const newState = {...state};

      newState.mindbody.customPaymentMethods = action.payload;
      
      return newState;
    }


    default:
      return state;
  }
};

export default reducer;