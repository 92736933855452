import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/tenants/action';
import {Actions as BUSINESSACTIONS} from 'store/business/action';

const Tenants = ({tenants,fetchTenants}) => {

	useEffect(() => {
	    fetchTenants();
	}, []);

	// eventually this needs to get the business details for each of the identified IDs and allow a dropdown

	return (
		<React.Fragment>
			{/*<div>
				{tenants}
			</div>*/}
		</React.Fragment>
	)
}


const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {
      tenants: state.tenants,
      // integration: state.integration && state.integration.current
  }
}

const mapDispatchToProps = dispatch => ({
  fetchTenants: (item) => dispatch(ACTIONS.fetchTenants()),
  // getBusiness: (id) => dispatch(BUSINESSACTIONS.getItem(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tenants) 
