import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  integration: {}
};

// BUSINESS_GET_ITEM
// BUSINESS_UPDATE_ITEM
// BUSINESS_CREATE_ITEM

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.INTEGRATION_LIST_ITEMS: {

      let newState = {...state};

      newState.integrations = action.payload.data.integrationSettingsByBusinessbyIntegrationType.items;

      console.log('listItems',action.payload)

      return newState;
    }
    case Types.INTEGRATION_GET_ITEM: {

      let newState = {...state};

      newState.integration = action.payload.data.getIntegrationSettings;

      console.log('actionOfGETITEM',action)
      if (action.copy){
        console.log('isCopy')
        newState.integration.name = `Copy of ${newState.integration.name}`
        delete newState.integration.id
        newState.integration.status = 'Disabled'
      }

      console.log('gotItem',action.payload)

      newState.integration.settings = JSON.parse(newState.integration.settings)

      if (newState.integration.settings['xeroTenant']){
        // if there is a Xero Tenant set it so we get the right info
        newState.xero.tenant = newState.integration.settings['xeroTenant'];
      }


      return newState;
    }
    case Types.INTEGRATION_REPLACE_APP: {

      let newState = {...state};

      if (newState.integration && newState.integration && newState.integration.apps){
        newState.integration.apps = [
          ...(newState.integration.apps.filter(id => id != action.oldApp)),
          action.newApp
        ]
      } else {
        newState.integration.apps = [action.newApp]
      }

      console.log('newIntegrationSettingUpdated',newState.integration)

      return newState;
    }
    case Types.INTEGRATION_UPDATE_ITEM: {

      let newState = {...state};

      newState.integration = action.payload.data.updateIntegrationSettings;

      console.log('newIntegrationSettingUpdated',action.payload.data)


      newState.integration.settings = JSON.parse(newState.integration.settings)

      return newState;
    }
    case Types.INTEGRATION_CREATE_ITEM: {

      let newState = {...state};

      newState.integration = action.payload.data.createIntegrationSettings;

      console.log('newIntegrationSettingCreated',newState.integration)


      newState.integration.settings = JSON.parse(newState.integration.settings)

      return newState;
    }
    case Types.INTEGRATION_INITIALISE_SETTING: {

      let newState = {
        ...state,
        integration: {
          settings: {
            ...action.init
          }
        }
      };

      return newState;
    }
    case Types.INTEGRATION_UPDATE_SETTING: {

      let newState = _.cloneDeep(state);

      console.log('updating integraiton setting',action)


      if (action.key == 'name'){
        // update name of the integration not a setting
        _.set(newState.integration, action.key, action.value);
      } else {
        _.set(newState.integration.settings, action.key, action.value);
      }
      // newState.integration.settings[action.key] = action.value;

      console.log('newState.integration',newState.integration.settings,action.key)

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;