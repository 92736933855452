import {Types} from "./action";
import _ from "lodash";


const defaultState = {
};

// TRACKING_CATEGORIES
// ACCOUNT_CODES
// CONNECTED_TO_XERO

const reducer = (state = defaultState, action) => {
  switch (action.type) {


    case Types.STRIPE_LIST: {

      const newState = {...state};

      newState.subscription.stripe.list = action.payload
      
      return newState;
    }

    case Types.STRIPE_CREATE_CUSTOMER: {

      const newState = {...state};

      newState.business.item.stripeID = action.payload.id
      
      return newState;
    }

    case Types.STRIPE_CREATING_SUBSCRIPTION: {

      const newState = {...state};

      newState.subscription.stripe.creatingSubscription = true
      
      return newState;
    }

    case Types.STRIPE_CREATE_SUBSCRIPTION: {

      const newState = {...state};

      newState.subscription.stripe.list = [
        ...newState.subscription.stripe.list,
        action.payload
      ]

      newState.subscription.stripe.creatingSubscription = false
      
      return newState;
    }

    case Types.STRIPE_INTENT: {

      const newState = {...state};

      newState.subscription.stripe.intent = action.payload
      
      return newState;
    }
    case Types.STRIPE_PAYMENT_METHODS: {

      const newState = {...state};

      newState.subscription.stripe.paymentMethods = action.payload
      
      return newState;
    }


    default:
      return state;
  }
};

export default reducer;