import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

// import {Actions as ACTIONS} from 'store/integration/action';
import Xero from '../xero/Xero';
import Mindbody from '../mindbody/Mindbody';
import PageHeading from 'components/PageHeading';
import LocationRow from './LocationRow';
import {Actions as ACTIONS} from 'store/integration/action';
import {Actions as XEROACTIONS} from 'store/xero/action';
import {Actions as MINDBODYACTIONS} from 'store/mindbody/action';
import Select from 'react-select';
import _ from 'lodash';
import { useParams, useHistory} from "react-router";
import uuidv4 from 'uuid/v4'

const XeroMindbody = ({
	integration, 
	getIntegrationSettings, 
	initialiseSettings,
	createIntegrationSettings, 
	updateIntegrationSettings, 
	xero, 
	starrez, 
	businessID,
	updateSetting,

	getXeroTrackingCategories,
	getXeroTaxRates,
	getXeroAccounts,

	xeroTrackingCategories,
	xeroAccounts,
	xeroTaxRates,

	xeroTenant,
	name,
	locationSettings,
	revenueCategorySettings,
	paymentMethodSettings,
	defaultSalesAccountCode,
	syncStartDate,
	emailNotificationRecipient,

	apps,
	integrationApps,

	locations,
	services,
	revenueCategories,
	customPaymentMethods,
	getLocations,
	getServices,
	getCustomPaymentMethods,

}) => {

	let { id, action } = useParams();

	useEffect(() => {
		console.log('id',integration && integration.id)
		if (!(integration && integration.id && integration.id == id) && id != 'new'){
			console.log('getIntegrationSettings')
			getIntegrationSettings(id,action == 'copy')
		}

		if (id == 'new'){
			initialiseSettings({
				location: {},
				revenueCategory: {},
				paymentMethod: {},
			})
		}
	},[id]);

	useEffect(() => {
		xeroTenant && getXeroTrackingCategories(xeroTenant)
		xeroTenant && getXeroTaxRates(xeroTenant)
		xeroTenant && getXeroAccounts(xeroTenant)
	},[xeroTenant]);

	console.log('xeroTenant',xeroTenant)


	const headingActions = [
		{
			label: "Save",
			onClick: () => {

				console.log('integrationApps',integrationApps)

				const externalIDs = [] 

				if (integration && integration.settings){
					if (integration.settings.xeroTenant){
						externalIDs.push({
							app: "Xero",
							externalID: integration.settings.xeroTenant,
						})
					}

					const mindBodyApp = apps && integrationApps && apps
						.filter(({app}) => app == "MindBody")
						.find(({id}) => integrationApps.includes(id) )


					const mindBodyConfig = mindBodyApp && mindBodyApp.config && JSON.parse(mindBodyApp.config)

					if (mindBodyConfig){
						externalIDs.push({
							app: "MindBody",
							externalID: mindBodyConfig.siteId,
						})
					}
				}
				
				if (integration && integration.id){

					const updatedIntegration = {
						...integration,
						name,
						type: 'XeroMindBody',
						settings: integration && JSON.stringify(integration.settings),
						apps: integrationApps
					}

					console.log('updatedIntegration',updatedIntegration)

					delete updatedIntegration._deleted
					delete updatedIntegration._lastChangedAt

					updateIntegrationSettings(updatedIntegration,externalIDs);
				} else {

					const updatedIntegration = {
						name,
						type: 'XeroMindBody',
                		businessID,
                		// userID: "eu-west-1:d236d6cd-157c-4c9b-9d7a-e4754a3e0452",
						id: uuidv4(),
						settings: integration && JSON.stringify(integration.settings),
						apps: integrationApps
					}
					console.log('updatedIntegration',updatedIntegration)
					createIntegrationSettings(updatedIntegration,externalIDs);

					//todo redirect to updatedIntegration.id
				}
			}
		}
	]

	const xeroBankAccounts = xeroAccounts && xeroAccounts.filter(account => account.Type == "BANK").map(item => ({
		label: item.Name,
		value: item.AccountID
	})) || []

	const xeroCurrentLiabilityAccounts = xeroAccounts && xeroAccounts.filter(account => account.Type == "CURRLIAB").map(item => ({
		label: item.Name,
		value: item.AccountID
	})) || []

	const xeroCurrentLiabilityAccountsWithCode = xeroAccounts && xeroAccounts.filter(account => account.Type == "CURRLIAB" && account.Code).map(item => ({
		label: item.Name,
		value: item.Code
	})) || []

	const xeroSalesAccounts = xeroAccounts && xeroAccounts.filter(account => ["REVENUE","PREPAYMENT","SALES","OTHERINCOME"].includes(account.Type)).map(item => ({
		label: item.Name,
		value: item.Code
	})) || []

	const xeroBankPlusCurrentLiability = xeroBankAccounts && xeroCurrentLiabilityAccounts && [
		{
	      label: "Bank Accounts",
	      options: xeroBankAccounts
	    },
	    {
	      label: "Current Liability",
	      options: xeroCurrentLiabilityAccounts
	    },
	]

	const xeroBankPlusCurrentLiabilitySearch = xeroBankAccounts && xeroCurrentLiabilityAccounts && [
		...xeroBankAccounts,
		...xeroCurrentLiabilityAccounts
	]

	const xeroSalesPlusCurrentLiability = xeroSalesAccounts && xeroCurrentLiabilityAccounts && [
	    {
	      label: "Sales",
	      options: xeroSalesAccounts
	    },
	    {
	      label: "Current Liability",
	      options: xeroCurrentLiabilityAccountsWithCode
	    },
	]

	const xeroSalesPlusCurrentLiabilitySearch = xeroBankAccounts && xeroCurrentLiabilityAccounts && [
		...xeroSalesAccounts,
		...xeroCurrentLiabilityAccountsWithCode
	]

	const xeroApp = apps && integrationApps && apps
		.filter(({app}) => app == "Xero")
		.find(({id}) => integrationApps.includes(id) )

	const mindBodyApp = apps && integrationApps && apps
		.filter(({app}) => app == "MindBody")
		.find(({id}) => integrationApps.includes(id) )

	useEffect(() => {
		
		if ( mindBodyApp && mindBodyApp.id){
			getLocations()
			getServices()
			getCustomPaymentMethods()
		}

	},[mindBodyApp && mindBodyApp.id]);

	const selectDefaultStyle = {
	  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
	};

	const onChange = (e) => {
		console.log('onChange',e)
	    updateSetting && updateSetting(e.target.name,e.target.value)
	}

	const inputValues = {
		'name' : {
			label: 'Integration Name',
			value: name,
			type: 'input'
		},
		'syncStartDate' : {
			label: 'sync Start Date',
			value: syncStartDate || "",
			type: 'input',
		},
		/*'trackingCategory1' : {
			label: 'Xero Tracking Category 1',
			value: trackingCategory1,
			type: 'select',
			options: xeroTrackingCategoryOptions
		},
		'trackingCategory2' : {
			label: 'Xero Tracking Category 2',
			value: trackingCategory2,
			type: 'select',
			options: xeroTrackingCategoryNameOptions
		},*/
		'defaultSalesAccountCode' : {
			label: 'Default Account Code',
			value: defaultSalesAccountCode,
			type: 'select',
			options: xeroSalesPlusCurrentLiability
		},
		'emailNotificationRecipient' : {
			label: 'Send Email Notifications to (comma separated)',
			value: emailNotificationRecipient || "",
			type: 'input',
		},
	}

	const defaultPaymentMethods = [
		// On Account Payments remain as pending invoices
		// {
		// 	Id: 16,
		// 	Name: "Account"
		// },
		{
			Id: 1,
			Name: "Cash"
		},
		{
			Id: 2,
			Name: "Cheque"
		},
		{
			Id: 4,
			Name: "Visa/MC"
		},
		{
			Id: 98,
			Name: "Prepaid Gift Card"
		}
	]

	console.log('locationSettings',locationSettings)

	return (
		<React.Fragment>
			<PageHeading isNew={false} labels={[`XERO - MINDBODY`]} actions={headingActions}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

				<div className="mt-10 sm:mt-0">
				  <div className="md:grid md:grid-cols-6 md:gap-6">

					<div className="bg-white shadow overflow-hidden sm:rounded-lg sm:col-span-3">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      XERO
					    </h3>
					    {/*<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
					      Personal details and application.
					    </p>*/}
					  </div>
					  <div>
					  	<div className="px-4 py-5 ">
					    	<Xero id={xeroApp && xeroApp.id} />
					    </div>
					  </div>
					</div>

					<div className="bg-white shadow overflow-hidden sm:rounded-lg sm:col-span-3">
					  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
					    <h3 className="text-lg leading-6 font-medium text-gray-900">
					      MINDBODY
					    </h3>
					    {/*<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
					      Personal details and application.
					    </p>*/}
					  </div>
					  <div>
					  	<div className="px-4 py-5 ">
					    	<Mindbody id={mindBodyApp && mindBodyApp.id} />
					    </div>
					  </div>
					</div>
				  </div>

				  { mindBodyApp && mindBodyApp.id && xeroTenant &&

				  	<React.Fragment>

						<div className="bg-white shadow sm:rounded-lg sm:col-span-6 mt-6">
						  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
						    <h3 className="text-lg leading-6 font-medium text-gray-900">
						      General Configuration
						    </h3>
						  </div>
						  <div>
						  	<div className="px-4 py-5 ">
							  	{
							  		Object.keys(inputValues).map(fieldName => (
							  			<div class="col-span-6 sm:col-span-4 mb-4">
								    		<label htmlFor={fieldName} class="block text-sm font-medium leading-5 text-gray-700">{inputValues[fieldName].label}</label>
							  				{	inputValues[fieldName].type == 'input' ? 
									    			<input id={fieldName} name={fieldName} onChange={onChange} value={inputValues[fieldName].value} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
												    : 	
												    <Select
											          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
											          styles={selectDefaultStyle}
											          value={inputValues[fieldName].options.find(item => item.value == inputValues[fieldName].value) || {value:"",label:""}}
											          onChange={(selected) => {
											              updateSetting && updateSetting(fieldName,selected.value)
											            }
											          }
											          options={inputValues[fieldName].options}
											        />
											}
									    </div>
							  		))
							  	}
						    </div>
						  </div>

						</div>

						<div className="bg-white shadow sm:rounded-lg sm:col-span-6 mt-6 mt-6">
						  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
						    <h3 className="text-lg leading-6 font-medium text-gray-900">
						      Locations
						    </h3>
						  </div>
						  <div>
						  	<table class="min-w-full">
						        <thead>
						          <tr>
						            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
						            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
						              Location
						            </th>
						            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
						              Tax 1
						            </th>
						            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
						              Tax 2
						            </th>
						            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
						              Tax 3
						            </th>
						            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
						              Tax 4
						            </th>
						            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
						              Tax 5
						            </th>
						          </tr>
						        </thead>
						        <tbody>
						        	{locations && locations.map((location,rowNo) => (
						        		<LocationRow key={location.Id} location={location} rowNo={rowNo}/>
						        	))}
						        </tbody>
						    </table>
						  </div>
						</div>


						<div className="bg-white shadow sm:rounded-lg sm:col-span-6 mt-6 mt-6">
						  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
						    <h3 className="text-lg leading-6 font-medium text-gray-900">
						      Revenue Categories
						    </h3>
						  </div>
						  <div>
						  	<div className="px-4 py-5 md:grid md:grid-cols-6 md:gap-6">
					  			<div class="col-span-3 sm:col-span-3">
					  				<label>MINDBODY Revenue Category</label>
					  			</div>
					  			<div class="col-span-3 sm:col-span-3">
					  				<label>Xero Account</label>
					  			</div>
							  	{
							  		revenueCategories && [...revenueCategories, "Tip", "GiftCard"].map((revenueCategory,index) => (
							  			<React.Fragment key={revenueCategory}>
								  			<div class="col-span-3 sm:col-span-3">
										    	{revenueCategory}
										    </div>
								  			<div class="col-span-3 sm:col-span-3">
											   	<Select
										          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
										          styles={selectDefaultStyle}
											      value={ revenueCategorySettings && revenueCategorySettings[revenueCategory] && xeroSalesPlusCurrentLiabilitySearch && xeroSalesPlusCurrentLiabilitySearch.find(item => item.value == revenueCategorySettings[revenueCategory]) || {value:"",label:""}}
										          
										          onChange={(selected) => {
										              updateSetting && updateSetting([`revenueCategory`,revenueCategory],selected.value)
										            }
										          }
										          options={xeroSalesPlusCurrentLiability}
										        />
										    </div>
										</React.Fragment>
							  		))
							  	}
						    </div>
						  </div>
						</div>

						<div className="bg-white shadow sm:rounded-lg sm:col-span-6 mt-6 mt-6">
						  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
						    <h3 className="text-lg leading-6 font-medium text-gray-900">
						      Payment Methods
						    </h3>
						  </div>
						  <div>
						  	<div className="px-4 py-5 md:grid md:grid-cols-6 md:gap-6">
					  			<div class="col-span-3 sm:col-span-3">
					  				<label>MINDBODY Payment Method</label>
					  			</div>
					  			<div class="col-span-3 sm:col-span-3">
					  				<label>Xero Bank Account</label>
					  			</div>
							  	{
							  		[...defaultPaymentMethods,...customPaymentMethods].map((paymentMethod,index) => (
							  			<React.Fragment key={paymentMethod.Id}>
								  			<div class="col-span-3 sm:col-span-3">
										    	{paymentMethod.Name}
										    </div>
								  			<div class="col-span-3 sm:col-span-3">
											   	<Select
										          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
										          styles={selectDefaultStyle}
											      value={ paymentMethodSettings && paymentMethodSettings[paymentMethod.Id] && xeroBankPlusCurrentLiabilitySearch && xeroBankPlusCurrentLiabilitySearch.find(item => item.value == paymentMethodSettings[paymentMethod.Id]) || {value:"",label:""}}
										          
										          onChange={(selected) => {
										              updateSetting && updateSetting(`paymentMethod[${paymentMethod.Id}]`,selected.value)
										            }
										          }
										          options={xeroBankPlusCurrentLiability}
										        />
										    </div>
										</React.Fragment>
							  		))
							  	}
						    </div>
						  </div>
						</div>
					</React.Fragment>
				  }

				</div>
			</div>
		</React.Fragment>
	)
}


const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {
	  	apps: state.apps,	  	

      	integration: state.integration,
	  	integrationApps: state.integration && state.integration.apps,
      	businessID: state.businessID,

	  	xeroTrackingCategories: state.xero && state.xero.trackingCategories,
	  	xeroAccounts: state.xero && state.xero.accounts,
	  	xeroTaxRates: state.xero && state.xero.taxrates,

	  	xeroTenant: state.integration && state.integration.settings && state.integration.settings.xeroTenant,
	  	name: state.integration && state.integration.settings && state.integration.name,
	  	locationSettings: state.integration && state.integration.settings && state.integration.settings.location,
	  	revenueCategorySettings: state.integration && state.integration.settings && state.integration.settings.revenueCategory,
	  	paymentMethodSettings: state.integration && state.integration.settings && state.integration.settings.paymentMethod,
	  	defaultSalesAccountCode: state.integration && state.integration.settings && state.integration.settings.defaultSalesAccountCode,
		syncStartDate: state.integration && state.integration.settings && state.integration.settings.syncStartDate,
		emailNotificationRecipient: state.integration && state.integration.settings && state.integration.settings.emailNotificationRecipient,

	  	locations: state.mindbody && state.mindbody.locations,
	  	services: state.mindbody && state.mindbody.services,
	  	revenueCategories: state.mindbody && state.mindbody.services && _.uniq(state.mindbody.services.map(({RevenueCategory}) => RevenueCategory)),
	  	customPaymentMethods: state.mindbody && state.mindbody.customPaymentMethods,

  }
}

const mapDispatchToProps = dispatch => ({

  updateIntegrationSettings: (item,externalIDs) => dispatch(ACTIONS.updateItem(item,externalIDs)),
  createIntegrationSettings: (item,externalIDs) => dispatch(ACTIONS.createItem(item,externalIDs)),
  getIntegrationSettings: (id,action) => dispatch(ACTIONS.getItem(id,action)),
  initialiseSettings: (init) => dispatch(ACTIONS.initialiseSettings(init)),
  updateSetting: (key,value) => dispatch(ACTIONS.updateSetting(key,value)),
  getXeroTrackingCategories: (tenant) => dispatch(XEROACTIONS.getTrackingCategories(tenant)),
  getXeroTaxRates: (tenant) => dispatch(XEROACTIONS.getTaxRates(tenant)),
  getXeroAccounts: (tenant) => dispatch(XEROACTIONS.getAccounts(tenant)),

  getLocations: () => dispatch(MINDBODYACTIONS.getLocations()),
  getServices: () => dispatch(MINDBODYACTIONS.getServices()),
  getCustomPaymentMethods: () => dispatch(MINDBODYACTIONS.getCustomPaymentMethods()),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(XeroMindbody) 
