import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/business/action';
import {Actions as SUBSCRIPTIONACTIONS} from 'store/subscription/action';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {format} from 'date-fns';
import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_51HGJFvARqlCjj4b93T9kQAaz4tcWB5SuiFTe8L1IZKOdNAgwSpHaxlqtCITuApuGzARrlpbMByzTdmuUxTLFV1yT003TWNMZmx");
// const stripePromise = loadStripe("pk_test_51HGJFvARqlCjj4b98dGKPv0wmGuBnhd1UlEELmE0B7LWglLJi03i6ieQDjZ3IOu8Hd5oshnSTaUsUPRlLVcBwvUT00mnga5GLc");


const stripePriceToProductName = {
	// sandbox
	// "price_1HHxOAARqlCjj4b93rtAcFWj": "Xero Mindbody Integration",
	// "price_1HJm9wARqlCjj4b9HjK78dNq": "Xero StarRez Integration",
	// "price_1HJmAoARqlCjj4b9u7GoHobH": "Xero X-Cart Integration",

	// live
	"price_1HHxMnARqlCjj4b9eHePEQFO": "Xero Mindbody Integration",
	"price_1HJmr3ARqlCjj4b9k8k4847T": "Xero StarRez Integration",
	"price_1HJmqmARqlCjj4b9EtH3O9aS": "Xero X-Cart Integration",
}

const Subscriptions = ({
	business, 
	stripePaymentMethods,
	stripeSubscriptions,
	getStripeSubscriptions,
	getStripePaymentMethods,
	createStripeSubscription,
}) => {

  	const [addNewCard, setAddNewCard] = useState(false)

  	// const [sidebarOpen, setSidebarOpen] = useState(false)
	useEffect(() => {
		if (business){
			getStripeSubscriptions()
			getStripePaymentMethods()
		}
	},[business && business.id]);

	useEffect(() => {
		if (stripePaymentMethods && stripePaymentMethods.length == 0){
			setAddNewCard(true)
		} else if (
			stripePaymentMethods && stripePaymentMethods.length > 0 && 
			stripeSubscriptions && stripeSubscriptions.length == 0
		){
			createStripeSubscription()
		}
	},[
		stripePaymentMethods && stripePaymentMethods.length,
		stripeSubscriptions && stripeSubscriptions.length,
	]);

	if (!business){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	const currentSubscription = stripeSubscriptions && stripeSubscriptions[0]

	const subscriptionItems = currentSubscription && (currentSubscription.item ? [currentSubscription.item] : currentSubscription.items.data)


	return (
		<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
			

			<div class="bg-white shadow sm:rounded-lg mb-10">
			  <div class="px-4 py-5 sm:p-6">
			    <h3 class="text-lg leading-6 font-medium text-gray-900">
			      Your Subscription
			    </h3>
			    	{
			    		subscriptionItems && subscriptionItems.map(item => 
			    			<div class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between mt-5">
						        <div class="sm:flex sm:items-start">
						          <div class="mt-3 sm:mt-0 sm:ml-4">
						            <div class="text-sm leading-5 font-medium text-gray-900">
						              {stripePriceToProductName[item.price.id]}
						            </div>
						            <div class="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
						              <div>
						                Unit Amount €{(item.plan.amount / 100).toFixed(2)}
						              </div>
						              <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">
						                &middot;
						              </span><div>
						                Quantity {item.quantity}
						              </div>
						              <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">
						                &middot;
						              </span>
						              <div class="mt-1 sm:mt-0">
						                Subscribed on {format(new Date(item.created * 1000), "do MMM yyyy")}
						              </div>
						            </div>
						          </div>
						        </div>
						        <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
						          <span class="inline-flex rounded-md">
						            €{(item.plan.amount / 100 * item.quantity).toFixed(2)}
						          </span>
						        </div>
						      </div>
			    		)
			    	}
			  </div>
			</div>
			

			<div class="bg-white shadow sm:rounded-lg">
			  <div class="px-4 py-5 sm:p-6">
			    <h3 class="text-lg leading-6 font-medium text-gray-900">
			      Payment method
			    </h3>
					{
						stripePaymentMethods && stripePaymentMethods.map(item => {
							console.log('paymentMethods',item)
							return (
						      <div class="mt-5 rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
						        <div class="sm:flex sm:items-start">
						          <svg class="h-8 w-auto sm:flex-shrink-0 sm:h-6" fill="none" viewBox="0 0 36 24" role="img" aria-labelledby="svg-visa">
						            <title id="svg-visa">VISA</title>
						            <rect width="36" height="24" fill="#224DBA" rx="4" />
						            <path fill="#fff" fill-rule="evenodd" d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z" clip-rule="evenodd" />
						          </svg>
						          <div class="mt-3 sm:mt-0 sm:ml-4">
						            <div class="text-sm leading-5 font-medium text-gray-900">
						              Ending with {item.card.last4}
						            </div>
						            <div class="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
						              <div>
						                Expires {item.card.exp_month}/{item.card.exp_year}
						              </div>
						              <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">
						                &middot;
						              </span>
						              <div class="mt-1 sm:mt-0">
						                Last updated on {format(new Date(item.created * 1000), "do MMM yyyy")}
						              </div>
						            </div>
						          </div>
						        </div>
						        <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
						          <span class="inline-flex rounded-md shadow-sm">
						            <button type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
						              Edit
						            </button>
						          </span>
						        </div>
						      </div>
							)
						})
					}

				    {addNewCard && 
				    	<Elements stripe={stripePromise}>
					      <CheckoutForm />
					    </Elements>
					}
			  </div>
			</div>
		</div>
	)
}


const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {
      business: state.business.item,
      stripePaymentMethods: state.subscription.stripe.paymentMethods,
      stripeSubscriptions: state.subscription.stripe.list,
  }
}

const mapDispatchToProps = dispatch => ({
  getStripeSubscriptions: () => dispatch(SUBSCRIPTIONACTIONS.getStripeSubscriptions()),
  getStripePaymentMethods: () => dispatch(SUBSCRIPTIONACTIONS.getStripePaymentMethods()),
  createStripeSubscription: () => dispatch(SUBSCRIPTIONACTIONS.createStripeSubscription()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscriptions) 
